var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { ErrorMessageBox, FallbackComponent } from '../common';
import { FormField } from '../common/formField';
import { useAddCompanyAPI } from './selectors';
var defaultValues = {
    name: '',
};
var validateForm = yup.object().shape({
    name: yup.string().required().trim(),
});
export var AddCompanyDialog = function (_a) {
    var open = _a.open, onClose = _a.onClose;
    var t = useTranslation(['manageUser', 'translation']).t;
    var _b = useAddCompanyAPI(), addCompanyData = _b[0], addCompany = _b[1], clearAddCompanyData = _b[2];
    var _c = useForm({
        defaultValues: defaultValues,
        resolver: yupResolver(validateForm),
    }), control = _c.control, handleSubmit = _c.handleSubmit, reset = _c.reset;
    useEffect(function () {
        if (open) {
            reset(defaultValues);
            return clearAddCompanyData;
        }
    }, [clearAddCompanyData, open, reset]);
    useEffect(function () {
        if (addCompanyData.data) {
            onClose();
        }
    }, [addCompanyData.data, onClose]);
    var handleFormSubmit = function (values) {
        addCompany(values);
    };
    return (_jsx(Dialog, __assign({ open: open, onClose: onClose }, { children: _jsxs(Box, __assign({ component: "form", onSubmit: handleSubmit(handleFormSubmit) }, { children: [_jsx(DialogTitle, __assign({ variant: "h2" }, { children: t('addNewCompanyTittle') })), _jsx(DialogContent, { children: _jsxs(FallbackComponent, __assign({ requesting: addCompanyData.requesting, overlay: true }, { children: [_jsx(ErrorMessageBox, { errors: [addCompanyData.error] }), _jsx(FormField, __assign({ required: true, name: "name", label: t('companyName'), control: control }, { children: _jsx(TextField, { inputProps: {
                                        maxLength: 50,
                                    } }) }))] })) }), _jsxs(DialogActions, { children: [_jsx(Button, __assign({ variant: "outlined", onClick: onClose }, { children: t('translation:action.cancel') })), _jsx(Button, __assign({ variant: "contained", type: "submit", disabled: addCompanyData.requesting }, { children: t('translation:action.save') }))] })] })) })));
};
