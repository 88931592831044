var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, FormControlLabel, Grid, InputAdornment, Radio, styled } from '@mui/material';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { usePlatformContext, useUserPermission } from '../app/selectors';
import { Autocomplete, FormField, InputLabel, NumberInput, RadioGroup } from '../common';
import { useReportAPI } from '../report/selectors';
import { HeadcountType, useDailyContext } from './context';
import { useDailyAPI, useNextQueueId, useQueueById, useUniqueQueues, useWAPQueuesAPI } from './selectors';
var StyledForm = styled(Box)(function (_a) {
    var theme = _a.theme;
    return ({
        display: 'flex',
        alignItems: 'end',
        backgroundColor: theme.palette.primary.light,
        padding: theme.spacing(8),
        borderRadius: theme.shape.borderRadius,
    });
});
var StyledHeadcount = styled(Box)(function (_a) {
    var theme = _a.theme;
    return ({
        display: 'flex',
        flexDirection: 'row',
        gap: theme.spacing(2.5),
    });
});
var initialValues = {
    headcount: 0,
    headcountType: HeadcountType.Reduction,
};
var validateForm = yup.object().shape({
    headcount: yup.number().required(),
    headcountType: yup.number().required(),
    planId: yup.number().required(),
    queueId: yup.number().required(),
    _queueId: yup.number().required(),
});
export var DailyForm = function () {
    var _a;
    var t = useTranslation(['report', 'translation']).t;
    var reportData = useReportAPI()[0];
    var queues = useUniqueQueues();
    var _b = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(validateForm),
    }), control = _b.control, watch = _b.watch, reset = _b.reset, handleSubmit = _b.handleSubmit, setValue = _b.setValue;
    var _c = useDailyContext(), state = _c[0], setState = _c[1];
    var _d = watch(), planId = _d.planId, _queueId = _d._queueId, queueId = _d.queueId;
    var _e = useWAPQueuesAPI(), queuesData = _e[0], fetchQueueData = _e[1], clearQueuesData = _e[2];
    var permission = useUserPermission();
    var queue = useQueueById(queueId);
    var nextQueueId = useNextQueueId(_queueId, planId);
    var headcountDisabled = !(permission === null || permission === void 0 ? void 0 : permission.dailyWorkArrivalPatternInterval) || (queue === null || queue === void 0 ? void 0 : queue.isDeferrable);
    var dailyData = useDailyAPI()[0];
    var context = usePlatformContext();
    useEffect(function () {
        fetchQueueData();
        return clearQueuesData;
    }, [clearQueuesData, fetchQueueData]);
    useEffect(function () {
        var _a, _b, _c;
        if (queues.length) {
            var values = __assign(__assign({}, initialValues), { planId: (_a = queues[0]) === null || _a === void 0 ? void 0 : _a.planId, _queueId: (_b = queues[0]) === null || _b === void 0 ? void 0 : _b.id, queueId: (_c = queues[0]) === null || _c === void 0 ? void 0 : _c.id });
            reset(values);
            setState(__assign(__assign({}, values), { month: 0 }));
        }
    }, [queues, reset, setState]);
    useEffect(function () {
        if (headcountDisabled) {
            setValue('headcount', initialValues.headcount);
            setValue('headcountType', initialValues.headcountType);
        }
    }, [headcountDisabled, setValue]);
    useEffect(function () {
        if (nextQueueId && nextQueueId !== queueId) {
            setValue('queueId', nextQueueId);
        }
    }, [queueId, nextQueueId, setValue]);
    var handleUpdate = handleSubmit(function (values) {
        state &&
            setState(__assign(__assign(__assign({}, state), values), { headcount: values.headcount * 0.01 * (values.headcountType === HeadcountType.Increase ? -1 : 1) }));
    });
    return (_jsx(StyledForm, __assign({ component: "form", onSubmit: handleUpdate }, { children: _jsxs(Grid, __assign({ container: true, spacing: 6 }, { children: [_jsx(Grid, __assign({ item: true, style: { flex: 1 } }, { children: _jsx(FormField, __assign({ required: true, name: "planId", label: t('resourcing.scenario'), control: control }, { children: _jsx(Autocomplete, { options: (_a = reportData.data) === null || _a === void 0 ? void 0 : _a.scenarios, getOptionLabel: function (o) { return (o.isBaseline ? 'Baseline' : o.name); }, loading: reportData.requesting }) })) })), _jsx(Grid, __assign({ item: true, style: { flex: 1 } }, { children: _jsx(FormField, __assign({ required: true, name: "_queueId", label: t('resourcing.queue', { context: context }), control: control }, { children: _jsx(Autocomplete, { options: queues, loading: queuesData.requesting }) })) })), _jsxs(Grid, __assign({ item: true }, { children: [_jsx(InputLabel, __assign({ required: true }, { children: t('daily.headcountChange') })), _jsxs(StyledHeadcount, { children: [_jsx(Box, { children: _jsx(FormField, __assign({ required: true, name: "headcount", control: control }, { children: _jsx(NumberInput, { InputProps: {
                                                endAdornment: _jsx(InputAdornment, __assign({ position: "end" }, { children: "%" })),
                                            }, max: 99.9, decimal: 1, disabled: headcountDisabled }) })) }), _jsx(FormField, __assign({ required: true, name: "headcountType", label: "", control: control }, { children: _jsxs(RadioGroup, __assign({ row: true }, { children: [_jsx(FormControlLabel, { value: HeadcountType.Reduction, control: _jsx(Radio, {}), label: t('daily.reduction'), disabled: headcountDisabled }), _jsx(FormControlLabel, { value: HeadcountType.Increase, control: _jsx(Radio, {}), label: t('daily.increase'), disabled: headcountDisabled })] })) }))] })] })), _jsxs(Grid, __assign({ item: true }, { children: [_jsx(InputLabel, __assign({ hidden: true }, { children: "button" })), _jsx(Button, __assign({ type: dailyData.requesting ? 'button' : 'submit', variant: "contained" }, { children: t('translation:action.update') }))] }))] })) })));
};
