var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTER_URL } from '../../router/constants';
import { ErrorMessageBox, ExitBar, FallbackComponent } from '../common';
import { PageBody } from '../layout/styled';
import { useIsViewOnlyPlanAPI } from '../report/selectors';
import { Actions } from './actions';
import { AttritionStep } from './attrition';
import { WizardStep } from './constants';
import { WizardContext, WizardProvider } from './context';
import { Description } from './description';
import { FinancialStep } from './financial';
import { HandleTimeStep } from './handleTime';
import { WizardLoader } from './loader';
import { QueuesStep } from './queue';
import { Review } from './review';
import { usePlanAPI, useWizardParams } from './selectors';
import { ShrinkageStep } from './shrinkage';
import { Steps } from './stepper';
import { SupportStaffStep } from './supportStaff';
import { WorkVolumeStep } from './workVolume';
import { WorkingDayStep } from './workingDays';
var ComponentByStep = (_a = {},
    _a[WizardStep.Queues] = QueuesStep,
    _a[WizardStep.WorkVolume] = WorkVolumeStep,
    _a[WizardStep.HandleTime] = HandleTimeStep,
    _a[WizardStep.Shrinkage] = ShrinkageStep,
    _a[WizardStep.Attrition] = AttritionStep,
    _a[WizardStep.WorkingDays] = WorkingDayStep,
    _a[WizardStep.SupportStaff] = SupportStaffStep,
    _a[WizardStep.Financial] = FinancialStep,
    _a[WizardStep.Review] = Review,
    _a);
var WizardWrapper = function () {
    var _a;
    var planData = usePlanAPI()[0];
    var isViewOnlyData = useIsViewOnlyPlanAPI()[0];
    var step = useWizardParams().step;
    var Component = ComponentByStep[step];
    var _b = useContext(WizardContext), handleSave = _b.handleSave, isDirty = _b.methods.formState.isDirty;
    var navigate = useNavigate();
    return (_jsxs(FallbackComponent, __assign({ requesting: (planData.requesting !== false && !planData.data) || !!isViewOnlyData.requesting }, { children: [_jsx(ErrorMessageBox, { errors: [planData.error, isViewOnlyData.error], sx: { margin: 3, marginBottom: 0 } }), _jsx(ExitBar, { title: (_a = planData.data) === null || _a === void 0 ? void 0 : _a.parent, isDirty: isDirty, onSave: handleSave('exit', function () { return navigate(ROUTER_URL.Home); }) }), _jsx(Steps, {}), !planData.error && (_jsxs(PageBody, { children: [_jsx(Description, {}), _jsx(Component, {}), _jsx(Actions, {})] }))] })));
};
export var Wizard = function () {
    var step = useWizardParams().step;
    return (_jsxs(_Fragment, { children: [_jsx(WizardLoader, {}), _jsx(WizardProvider, { children: _jsx(WizardWrapper, {}) }, step)] }));
};
