var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
export var TRAINING_KEY_EDITABLE = [
    'forecastedGraduationRate',
    'trainingLength',
    'classSize',
    'recruitingLeadTime',
];
export var TRAINING_KEY_BOTTOM = [
    'totalNewHireRequired',
    'recruitingStartDate',
    'newHireClassStartDate',
    'trainerRequire',
];
export var TRAINING_KEY = __spreadArray(__spreadArray(__spreadArray([
    'attrition',
    'rosteredStaffPlanned',
    'forecastedAgentAttrition'
], TRAINING_KEY_EDITABLE, true), [
    'totalLeadTimeToGraduation'
], false), TRAINING_KEY_BOTTOM, true);
export var SUMMARY_TRAINING_KEY = [
    'totalNewHireRequired',
    'recruitingStartDate',
    'newHireClassStartDate',
    'trainerRequire',
];
export var SUMMARY_TRAINING_KEY_BOTTOM = ['totalNewHireRequired', 'trainerRequire'];
