var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Checkbox, FormControlLabel, Stack, Typography, styled, useTheme } from '@mui/material';
import _ from 'lodash';
import { useCallback, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { FormField, NativeNumberInput, Table, TableBody, TableCell, TableContainer, TableRow } from '../../common';
import { BaseTableHeader } from '../common/baseTable';
import { usePeriodChunkSize } from '../selectors';
import { useShrinkageTable } from './selectors';
var STICKIES = ['14.16rem'];
var TableCellTotal = styled(TableCell)(function (_a) {
    var theme = _a.theme;
    return ({
        color: theme.palette.common.white,
        backgroundColor: theme.palette.text.primary,
        fontWeight: 700,
    });
});
var StyledTableCell = styled(TableCell)(function (_a) {
    var theme = _a.theme;
    return ({
        minWidth: theme.typography.pxToRem(100),
    });
});
export var ShrinkageTotalRow = function (_a) {
    var addColumns = _a.addColumns;
    var theme = useTheme();
    var chunkSize = usePeriodChunkSize();
    var isIncludeAverage = addColumns === null || addColumns === void 0 ? void 0 : addColumns.includes('Average');
    var watchShrinkage = useWatch({ name: 'shrinkages' });
    var shrinkageFooterData = _.chain(watchShrinkage)
        .map(function (group, index) { return ({ month: index, percent: _.sumBy(group, function (item) { return Number(item.percent); }) }); })
        .chunk(chunkSize)
        .flatMap(function (chunk, index) {
        if (!isIncludeAverage)
            return chunk;
        return __spreadArray(__spreadArray([], chunk, true), [
            {
                id: "summary_".concat(index + 1),
                month: chunkSize * (index + 1) - 1 + 0.1,
                percent: (chunk.reduce(function (acc, item) { return acc + Number(item.percent); }, 0) / chunk.length).toFixed(2),
            },
        ], false);
    })
        .value();
    return (_jsxs(TableRow, { children: [_jsx(TableCellTotal, { color: theme.palette.text.primary, stickySizes: STICKIES, component: "th", align: "left", value: "Total" }), _.map(shrinkageFooterData, function (value, index, arr) {
                var isAverageColumn = value.month % 1 !== 0;
                return (_jsx(TableCellTotal, { highlightBorder: index !== arr.length - 1 && (isIncludeAverage ? isAverageColumn : !((index + 1) % chunkSize)), value: value.percent, decimal: 2, suffix: "%" }, value.month));
            })] }));
};
var ShrinkageTableBody = function (_a) {
    var disabled = _a.disabled, _b = _a.showCheckbox, showCheckbox = _b === void 0 ? false : _b, selectedRows = _a.selectedRows, setSelectedRows = _a.setSelectedRows, addColumns = _a.addColumns;
    var shrinkageData = useShrinkageTable();
    var _c = useFormContext(), control = _c.control, setValue = _c.setValue, watch = _c.watch;
    var chunkSize = usePeriodChunkSize();
    var watchShrinkageData = watch('shrinkages');
    var watchShrinkageByName = _.zipObject(_.keys(shrinkageData), _.zip.apply(_, (watchShrinkageData || [])).map(function (item) {
        return _.chunk(item.map(function (i) { return Number(i === null || i === void 0 ? void 0 : i.percent); }), chunkSize);
    }));
    var isIncludeAverage = addColumns === null || addColumns === void 0 ? void 0 : addColumns.includes('Average');
    var shrinkageDataWithTotal = _.chain(shrinkageData)
        .mapValues(function (group, key) {
        return _.chain(group)
            .chunk(chunkSize)
            .flatMap(function (chunk, index) {
            if (!isIncludeAverage)
                return chunk;
            return __spreadArray(__spreadArray([], chunk, true), [
                {
                    id: "summary_".concat(index + 1),
                    month: chunkSize * (index + 1) - 1 + 0.1,
                    percent: _.mean(watchShrinkageByName[key][index]).toFixed(2),
                },
            ], false);
        })
            .value();
    })
        .value();
    var handleCheckbox = useCallback(function (index, checked) {
        setSelectedRows === null || setSelectedRows === void 0 ? void 0 : setSelectedRows(function (previousRows) {
            return checked ? __spreadArray(__spreadArray([], previousRows, true), [index], false) : previousRows.filter(function (row) { return row !== index; });
        });
    }, [setSelectedRows]);
    return (_jsxs(TableBody, { children: [Object.entries(shrinkageDataWithTotal).map(function (_a, rowIdx) {
                var key = _a[0], values = _a[1];
                return (_jsxs(TableRow, { children: [_jsx(TableCell, __assign({ stickySizes: STICKIES, component: "th", align: "left" }, { children: _jsx(Stack, __assign({ direction: "row", alignItems: "center" }, { children: showCheckbox ? (_jsx(FormControlLabel, { control: _jsx(Checkbox, { size: "small", checked: selectedRows === null || selectedRows === void 0 ? void 0 : selectedRows.includes(rowIdx), onChange: function (e, checked) { return handleCheckbox(rowIdx, checked); } }), label: _jsx(Typography, __assign({ variant: "subtitle2" }, { children: key })) })) : (_jsx(Typography, __assign({ variant: "subtitle2" }, { children: key }))) })) })), values.map(function (cell, cellIdx, arr) {
                            var isAverageColumn = cell.month % 1 !== 0;
                            return (_jsx(StyledTableCell, __assign({ greyColor: isAverageColumn || disabled, padding: "none", highlightBorder: arr.length - 1 !== cellIdx && (isIncludeAverage ? isAverageColumn : !((cellIdx + 1) % chunkSize)) }, { children: isAverageColumn ? (cell.percent + '%') : (_jsx(FormField, __assign({ disableHelperText: true, required: true, name: "shrinkages.".concat(cell.month, ".").concat(rowIdx, ".percent"), control: control }, { children: _jsx(NativeNumberInput, { disabled: disabled, decimal: 2, min: 0, max: 99.99, setValue: setValue, suffix: "%" }) }))) }), cell.id));
                        })] }, key));
            }), _jsx(ShrinkageTotalRow, { addColumns: addColumns })] }));
};
export var ShrinkageTable = function (_a) {
    var setSelectedRows = _a.setSelectedRows, selectedRows = _a.selectedRows, props = __rest(_a, ["setSelectedRows", "selectedRows"]);
    var shrinkageData = useShrinkageTable();
    var allKeys = useMemo(function () { return Array.from(Object.keys(shrinkageData).keys()); }, [shrinkageData]);
    var handleSelectAllChange = useCallback(function (e, checked) {
        setSelectedRows === null || setSelectedRows === void 0 ? void 0 : setSelectedRows(checked ? allKeys : []);
    }, [allKeys, setSelectedRows]);
    return (_jsx(TableContainer, { children: _jsxs(Table, { children: [_jsx(BaseTableHeader, __assign({}, props, { stickySizes: STICKIES, selectAll: allKeys.length === (selectedRows === null || selectedRows === void 0 ? void 0 : selectedRows.length), handleSelectAllChange: handleSelectAllChange })), _jsx(ShrinkageTableBody, __assign({}, props, { selectedRows: selectedRows, setSelectedRows: setSelectedRows }))] }) }));
};
