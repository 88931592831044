var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton, loadingButtonClasses } from '@mui/lab';
import { Box, Typography, styled, useTheme } from '@mui/material';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePlatformContext } from '../../app/selectors';
import { ConfirmDialog } from '../../common/confirmDialog';
import { WORK_TYPE_ICON } from '../constants';
import { WizardContext } from '../context';
import { useQueueIncomplete, useQueuesAPI } from '../queue/selectors';
import { useWizardParams } from '../selectors';
var StyledLoadingButton = styled(LoadingButton)(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {},
        _b["&.".concat(loadingButtonClasses.root)] = {
            justifyContent: 'flex-start !important',
            gap: theme.spacing(3),
            ':disabled': {
                color: "".concat(theme.palette.text.primary, " !important"),
                backgroundColor: 'transparent',
                borderColor: "".concat(theme.palette.primary.main, " !important"),
            },
        },
        _b);
});
var StyledStack = styled(Box)(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {
            maxHeight: theme.typography.pxToRem(400)
        },
        _b["& button"] = {
            marginBottom: theme.typography.pxToRem(8),
        },
        _b["& button:last-child"] = {
            marginBottom: theme.typography.pxToRem(32),
        },
        _b);
});
var QueueButton = function (_a) {
    var queue = _a.queue;
    var t = useTranslation().t;
    var theme = useTheme();
    var queueId = useWizardParams().queueId;
    var incomplete = useQueueIncomplete(queue.id);
    var _b = useContext(WizardContext), handleSave = _b.handleSave, actionRef = _b.actionRef, requesting = _b.requesting, goToQueue = _b.goToQueue, isDirty = _b.methods.formState.isDirty;
    var _c = useState(false), open = _c[0], setOpen = _c[1];
    var handleClick = function () {
        if (isDirty) {
            setOpen(true);
            return;
        }
        goToQueue(queue.id);
    };
    var handleSkipSave = function () {
        setOpen(false);
        goToQueue(queue.id);
    };
    var handleConfirm = function () {
        setOpen(false);
        handleSave('switchQueue', function () { return goToQueue(queue.id); })();
    };
    return (_jsxs(_Fragment, { children: [_jsxs(StyledLoadingButton, __assign({ variant: "outlined", fullWidth: true, disabled: queue.id === queueId, loading: actionRef.current === 'switchQueue' && requesting && queue.id === queueId, onClick: handleClick }, { children: [_jsx(Typography, __assign({ variant: "h3" }, { children: _jsx(FontAwesomeIcon, { icon: WORK_TYPE_ICON[queue.worktype.id - 1] }) })), _jsxs(Box, __assign({ textAlign: "left" }, { children: [_jsx(Typography, __assign({ fontWeight: "bold" }, { children: queue.businessArea })), _jsx(Typography, __assign({ variant: "body2" }, { children: queue.worktype.name }))] })), incomplete && (_jsx(Typography, __assign({ ml: "auto", color: theme.palette.warning.main }, { children: _jsx(FontAwesomeIcon, { icon: "triangle-exclamation" }) })))] })), _jsx(ConfirmDialog, { open: open, onClose: function () { return setOpen(false); }, title: t('exitAlert.anotherTitle'), message: t('confirmation.confirmExit'), confirmButtonText: "saveContinue", skipSaveButtonText: "skipSave", onConfirm: handleConfirm, onSkipSaveConfirm: handleSkipSave })] }));
};
export var QueuePanel = function () {
    var _a;
    var t = useTranslation('wizard', { keyPrefix: 'queue' }).t;
    var queuesData = useQueuesAPI()[0];
    var context = usePlatformContext();
    return (_jsxs(_Fragment, { children: [_jsx(Typography, __assign({ variant: "h3", gutterBottom: true }, { children: t('name', { context: context }) })), _jsx(Typography, __assign({ mb: 5 }, { children: t('queuePanel.description', { context: context }) })), _jsx(StyledStack, { children: (_a = queuesData === null || queuesData === void 0 ? void 0 : queuesData.data) === null || _a === void 0 ? void 0 : _a.map(function (queue) { return _jsx(QueueButton, { queue: queue }, queue.id); }) })] }));
};
