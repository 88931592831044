var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PlanType } from '../../../enum';
import { useIsViewOnlyPlan } from '../../report/selectors';
import { BasePanel } from '../common/basePanel';
import { QueuePanel } from '../common/queuePanel';
import { StepContainer, StepPanel } from '../common/stepContainer';
import { useRefreshQueueData } from '../queue/selectors';
import { QueueStatusType } from '../queue/types';
import { usePlanType } from '../selectors';
import { AddRoleForm } from './addRoleForm';
import { CalculatorDialog } from './calculatorDialog';
import { RoleTable } from './roleTable';
import { useClearRoleAPI, useCopyRoleAPI } from './selectors';
export var SupportStaffStep = function () {
    var t = useTranslation('wizard', { keyPrefix: 'supportStaff' }).t;
    var _a = useState(false), open = _a[0], setOpen = _a[1];
    var refreshData = useRefreshQueueData();
    var _b = useCopyRoleAPI(), copyRoleData = _b[0], copyRole = _b[1], clearCopyRoleData = _b[2];
    var _c = useClearRoleAPI(), clearRoleData = _c[0], clearRole = _c[1], cleanClearRoleData = _c[2];
    var _d = useFormContext(), watch = _d.watch, setValue = _d.setValue;
    var planType = usePlanType();
    var isViewOnly = useIsViewOnlyPlan();
    useEffect(function () {
        if (clearRoleData.data || copyRoleData.data) {
            refreshData();
        }
        return function () {
            cleanClearRoleData();
            clearCopyRoleData();
        };
    }, [cleanClearRoleData, clearCopyRoleData, refreshData, clearRoleData.data, copyRoleData.data]);
    return (_jsx(StepContainer, __assign({ leftContent: _jsx(QueuePanel, {}) }, { children: _jsxs(StepPanel, { children: [_jsx(BasePanel, __assign({ title: t('title'), statusType: QueueStatusType.SupportStaff, onClear: clearRole, onCopy: copyRole, errors: [clearRoleData.error, copyRoleData.error], requesting: !!clearRoleData.requesting || !!copyRoleData.requesting, leftContent: _jsx(Button, __assign({ variant: "outlined", onClick: function () { return setOpen(true); }, disabled: planType === PlanType.Weekly || isViewOnly }, { children: t("trainerAndQaRatioCalculator") })) }, { children: _jsx(RoleTable, { disableFinancial: true }) })), _jsx(AddRoleForm, {}), _jsx(CalculatorDialog, { open: open, onClose: function () { return setOpen(false); }, setValue: setValue, watch: watch })] }) })));
};
