var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { PlanType } from '../../enum';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '../common';
import { useReportAPI } from '../report/selectors';
import { TimelineType } from '../wizard/selectors';
import { ADJUSTED_COLOR, FLAG, PERCENTAGES, SUMMARY_DATA_ROWS, metricTargetMap } from './constants';
import { useDailyAPI, usePlanColor, useReportTimeline } from './selectors';
var STICKIES = ['14.16rem'];
var SummaryTableHead = function (_a) {
    var _b;
    var _c = _a.headColor, headColor = _c === void 0 ? ADJUSTED_COLOR : _c, props = __rest(_a, ["headColor"]);
    var timelines = useReportTimeline(props.showYear, true, 'Total');
    var reportData = useReportAPI()[0];
    var planType = ((_b = reportData.data) !== null && _b !== void 0 ? _b : {}).planType;
    return (_jsx(TableHead, { children: timelines.map(function (_a, rowIdx) {
            var titles = _a[0], type = _a[1];
            return (_jsxs(TableRow, { children: [!rowIdx && (_jsx(TableCell, __assign({ rowSpan: timelines.length, color: headColor, stickySizes: STICKIES }, { children: props.title }))), titles === null || titles === void 0 ? void 0 : titles.map(function (title, cellIdx, arr) { return (_jsx(TableCell, __assign({ sx: { whiteSpace: 'nowrap' }, color: type !== TimelineType.Weeks ? headColor : undefined, colSpan: type === TimelineType.Years ? 13 : 1, highlightBorder: planType === PlanType.Monthly &&
                            cellIdx !== arr.length - 1 &&
                            (type === TimelineType.Years || !((cellIdx + 1) % 13)) }, { children: title }), cellIdx)); })] }, type));
        }) }));
};
var SummaryTableBody = function (_a) {
    var _b;
    var data = _a.data, bodyColor = _a.bodyColor, metricTarget = _a.metricTarget;
    var reportData = useReportAPI()[0];
    var planType = ((_b = reportData.data) !== null && _b !== void 0 ? _b : {}).planType;
    return (_jsx(TableBody, { children: _.map(SUMMARY_DATA_ROWS, function (value, key) {
            var _a;
            return (data === null || data === void 0 ? void 0 : data[key]) && (_jsxs(TableRow, { children: [_jsxs(TableCell, __assign({ stickySizes: STICKIES, component: "th", align: "left" }, { children: [value, metricTarget && metricTargetMap[key] === metricTarget && FLAG] })), (_a = data[key]) === null || _a === void 0 ? void 0 : _a.map(function (cell, cellIdx, arr) { return (_jsx(TableCell, { color: bodyColor, value: cell, percentage: PERCENTAGES.includes(key), highlightBorder: planType === PlanType.Monthly && cellIdx !== arr.length - 1 && !((cellIdx + 1) % 13), warning: key === 'forecastedOccupancy' && (data === null || data === void 0 ? void 0 : data.maxOccupancy) && cell >= (data === null || data === void 0 ? void 0 : data.maxOccupancy[cellIdx]) }, cellIdx)); })] }, key));
        }) }));
};
var NormalTable = function () {
    var _a, _b;
    var t = useTranslation('report', { keyPrefix: 'daily' }).t;
    var _c = usePlanColor(), headColor = _c[0], bodyColor = _c[1];
    var summaryData = useDailyAPI()[0];
    return (_jsxs(_Fragment, { children: [_jsx(SummaryTableHead, { title: t('fullyOptimized'), showYear: true, headColor: headColor }), _jsx(SummaryTableBody, { bodyColor: bodyColor, data: (_a = summaryData.data) === null || _a === void 0 ? void 0 : _a.summary.normal, metricTarget: (_b = summaryData.data) === null || _b === void 0 ? void 0 : _b.summary.metricTarget })] }));
};
var AdjustedTable = function () {
    var _a, _b;
    var t = useTranslation('report', { keyPrefix: 'daily' }).t;
    var summaryData = useDailyAPI()[0];
    return (_jsxs(_Fragment, { children: [_jsx(SummaryTableHead, { title: t('adjusted'), headColor: ADJUSTED_COLOR }), _jsx(SummaryTableBody, { data: (_a = summaryData.data) === null || _a === void 0 ? void 0 : _a.summary.adjusted, metricTarget: (_b = summaryData.data) === null || _b === void 0 ? void 0 : _b.summary.metricTarget })] }));
};
export var SummaryTable = function () {
    var data = useDailyAPI()[0].data;
    if (!data)
        return null;
    return (_jsx(TableContainer, __assign({ topScroll: true }, { children: _jsxs(Table, { children: [_jsx(NormalTable, {}), _jsx(AdjustedTable, {})] }) })));
};
