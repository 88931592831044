var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { createSelector } from '@reduxjs/toolkit';
import _ from 'lodash';
import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useAPI } from '../../state';
import { ActionType } from '../report/constants';
import { useReportDownload } from '../report/hooks';
import { useReportAPI } from '../report/selectors';
import { PLAN_COLORS } from '../wizard/constants';
import { selectChunkSize } from '../wizard/selectors';
var GET_TRAINING_REPORT_API = '/api/report/training?planId={planId}';
var UPDATE_TRAINING_API = '/api/trainingRecruitment';
var PRINT_API = '/api/report/training/print?planId={planId}';
var EXPORT_API = '/api/report/training/export?basePlanId={basePlanId}';
var EXPORT_FILE_NAME = 'Training_and_Recruitment_Output';
export var usePrintTableAPI = function (planId) {
    return useReportDownload(ActionType.Print, PRINT_API, "".concat(EXPORT_FILE_NAME, ".pdf"), {
        substitutions: { planId: planId },
        disabled: !planId,
    });
};
export var useExportAPI = function () {
    var basePlanId = useParams().basePlanId;
    return useReportDownload(ActionType.Export, EXPORT_API, "".concat(EXPORT_FILE_NAME, ".xlsx"), {
        substitutions: { basePlanId: basePlanId },
    });
};
export var useTrainingReportAPI = function (planId) {
    var _a = useAPI(GET_TRAINING_REPORT_API, { planId: planId }), data = _a[0], fetchData = _a[1], clearData = _a[2];
    var fetchDataWrapper = useCallback(function () {
        if (planId) {
            fetchData();
        }
    }, [fetchData, planId]);
    return [data, fetchDataWrapper, clearData];
};
export var useUpdateHeaderTrainingAPI = function () {
    return useAPI(UPDATE_TRAINING_API, undefined, 'POST');
};
export var useUpdateTableTrainingAPI = function () {
    return useAPI(UPDATE_TRAINING_API, undefined, 'PUT');
};
export var useScenarioSelected = function (id) {
    var _a;
    if (id === void 0) { id = 0; }
    var _b = useReportAPI()[0].data, _c = _b === void 0 ? {} : _b, _d = _c.scenarios, scenarios = _d === void 0 ? [] : _d;
    var _e = (_a = scenarios.find(function (sc) { return sc.id === id; })) !== null && _a !== void 0 ? _a : {}, _f = _e.isBaseline, isBaseline = _f === void 0 ? false : _f, _g = _e.name, name = _g === void 0 ? '' : _g, _h = _e.colorIndex, colorIndex = _h === void 0 ? 0 : _h;
    return {
        colors: PLAN_COLORS[colorIndex],
        name: isBaseline ? 'Baseline' : name,
    };
};
var useTrainingUnit = function (chunkSize, trainings) {
    return _.chain(trainings)
        .filter(function (_a) {
        var periods = _a.periods;
        return !!periods;
    })
        .chunk(chunkSize)
        .flatMap(function (chunk, index) { return __spreadArray(__spreadArray([], chunk, true), [
        {
            id: "total_".concat(index + 1),
            month: chunkSize * (index + 1) - 1 + 0.1,
            totalNewHireRequired: chunk.reduce(function (acc, item) { return acc + Number(item.totalNewHireRequired); }, 0) || '',
        },
    ], false); })
        .value();
};
var createTrainingSelector = function (trainingData) {
    return createSelector(selectChunkSize, function (chunkSize) {
        return _.chain(trainingData)
            .map(function (_a) {
            var id = _a.id, name = _a.name, trainings = _a.trainings;
            return ({
                id: id,
                name: name,
                trainings: useTrainingUnit(chunkSize, trainings),
            });
        })
            .value();
    });
};
var createTrainingSummarySelector = function (trainings) {
    return createSelector(selectChunkSize, function (chunkSize) { return useTrainingUnit(chunkSize, trainings); });
};
export var useTrainingSummaryTable = function (scenarioId) {
    if (scenarioId === void 0) { scenarioId = 0; }
    var data = useTrainingReportAPI(scenarioId)[0].data;
    var reportData = useReportAPI()[0].data;
    var selectTraining = useMemo(function () { var _a; return createTrainingSelector((_a = data === null || data === void 0 ? void 0 : data.queues) !== null && _a !== void 0 ? _a : []); }, [data]);
    var selectTrainingSummary = useMemo(function () { var _a; return createTrainingSummarySelector((_a = data === null || data === void 0 ? void 0 : data.summary) !== null && _a !== void 0 ? _a : []); }, [data]);
    return {
        queues: selectTraining(reportData),
        summary: selectTrainingSummary(reportData),
    };
};
