var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { usePlatformContext } from '../app/selectors';
import { Autocomplete, FormField, NumberInput } from '../common';
import { useIsViewOnlyPlan } from '../report/selectors';
export var TrainingRecruitmentForm = function (_a) {
    var reportData = _a.reportData, queueData = _a.queueData, _b = _a.disableButton, disableButton = _b === void 0 ? true : _b, control = _a.control, setCurrentScenario = _a.setCurrentScenario, setCurrentQueue = _a.setCurrentQueue;
    var t = useTranslation(['report', 'translation']).t;
    var isViewOnly = useIsViewOnlyPlan();
    var context = usePlatformContext();
    return (_jsxs(Stack, __assign({ spacing: 4 }, { children: [_jsx(Typography, __assign({ variant: "h3" }, { children: t('training.enterTrainingDetails') })), _jsx(FormField, __assign({ required: true, name: "scenarioId", label: t('training.chooseScenario'), control: control }, { children: _jsx(Autocomplete, { "data-testid": "select-scenario", options: reportData === null || reportData === void 0 ? void 0 : reportData.scenarios, getOptionLabel: function (o) { return (o.isBaseline ? 'Baseline' : o.name); }, onChange: setCurrentScenario }) })), _jsx(FormField, __assign({ required: true, name: "queueId", label: t('training.selectQueue', { context: context }), control: control }, { children: _jsx(Autocomplete, { "data-testid": "select-queue", options: queueData, getOptionLabel: function (o) { return o.name; }, onChange: setCurrentQueue }) })), _jsx(FormField, __assign({ required: true, name: "trainingLength", label: t('training.trainingLengthHead'), unitLabel: "WEEKS", control: control }, { children: _jsx(NumberInput, { min: 0, max: 24, disabled: isViewOnly }) })), _jsx(FormField, __assign({ required: true, name: "forecastedGraduationRate", label: t('training.forecastedGraduationRateHead'), unitLabel: "%", control: control }, { children: _jsx(NumberInput, { min: 1, max: 100, disabled: isViewOnly }) })), _jsx(FormField, __assign({ required: true, name: "classSize", label: t('training.classSize'), control: control }, { children: _jsx(NumberInput, { min: 1, max: 10000, disabled: isViewOnly }) })), _jsx(FormField, __assign({ required: true, name: "recruitingLeadTime", label: t('training.recruitingLeadTimeHead'), unitLabel: "WEEKS", control: control }, { children: _jsx(NumberInput, { min: 1, max: 10000, disabled: isViewOnly }) })), _jsx(Button, __assign({ style: { marginTop: '2rem' }, variant: "contained", type: "submit", disabled: disableButton || isViewOnly }, { children: t('translation:action.update') }))] })));
};
