var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Table as MuiTable, TableBody as MuiTableBody, TableCell as MuiTableCell, TableContainer as MuiTableContainer, TableFooter as MuiTableFooter, TableHead as MuiTableHead, TableRow as MuiTableRow, Paper, Stack, autocompleteClasses, checkboxClasses, outlinedInputClasses, styled, tableCellClasses, tableClasses, useTheme, } from '@mui/material';
import { useEffect, useRef } from 'react';
import { FLAG } from '../../daily/constants';
var TopScrollContainer = styled(Box)({
    overflow: 'auto',
});
var StyledTableContainer = styled(Paper)(function (_a) {
    var theme = _a.theme;
    return ({
        overflow: 'auto',
        backgroundColor: 'transparent',
        borderLeft: "1px solid ".concat(theme.palette.grey[600]),
    });
});
var StyledTable = styled(MuiTable)(function (_a) {
    var theme = _a.theme;
    return ({
        '> thead, > tfoot, tbody + tbody ': {
            '> tr:first-of-type': {
                '> th, > td': {
                    borderTopWidth: 1,
                    '&.sticky-cell': {
                        boxShadow: "inset 0 -1px ".concat(theme.palette.grey[600], ", 1px 0 ").concat(theme.palette.grey[600]),
                        outlineWidth: 0,
                    },
                },
            },
        },
        '> *:not(thead):not(:last-child) > tr:last-of-type': {
            '> th, > td': {
                boxShadow: 'none',
            },
        },
        '> tbody:last-of-type > tr:last-of-type > .sticky-cell': {
            borderBottomWidth: 1,
        },
    });
});
var StyledCell = styled(MuiTableCell)(function (_a) {
    var _b, _c;
    var theme = _a.theme, align = _a.align, color = _a.color;
    return (_b = {},
        _b["&.".concat(tableCellClasses.head)] = (_c = {
                color: theme.palette.common.white,
                fontWeight: theme.typography.fontWeightBold,
                backgroundColor: theme.palette.text.primary
            },
            _c[".".concat(checkboxClasses.checked)] = {
                color: theme.palette.common.white,
            },
            _c),
        _b["td&.".concat(tableCellClasses.head)] = {
            backgroundColor: theme.palette.grey[800],
        },
        _b["th&.".concat(tableCellClasses.body, ", th&.").concat(tableCellClasses.footer)] = {
            fontWeight: theme.typography.fontWeightBold,
            backgroundColor: theme.palette.grey[400],
        },
        _b["&.".concat(tableCellClasses.footer)] = {
            color: theme.palette.text.primary,
        },
        _b.fontSize = theme.typography.body1.fontSize,
        _b.border = "0 solid ".concat(theme.palette.grey[600]),
        _b.borderRightWidth = 1,
        _b.boxShadow = "inset 0 -1px ".concat(theme.palette.grey[600]),
        _b.textAlign = align !== null && align !== void 0 ? align : 'center',
        _b.paddingBottom = 1,
        _b.backgroundColor = color === 'greyColor' ? theme.palette.grey[400] : color ? "".concat(color, "!important") : theme.palette.common.white,
        _b["&:not(.".concat(tableCellClasses.paddingNone, ")")] = {
            padding: theme.spacing(2.5),
        },
        _b[".".concat(outlinedInputClasses.notchedOutline)] = {
            borderColor: 'transparent',
        },
        _b[".".concat(autocompleteClasses.root, " .").concat(outlinedInputClasses.root)] = {
            paddingRight: theme.spacing(8.75),
        },
        _b);
});
export var TableHead = styled(MuiTableHead)(function () { return ({
    position: 'sticky',
    top: 0,
    zIndex: 9,
}); });
var StyledExpandItem = styled(Box)(function (_a) {
    var theme = _a.theme;
    return ({
        border: "1px solid ".concat(theme.palette.grey[600]),
        width: theme.spacing(18),
        linHeight: 1.44,
        fontWeight: theme.typography.fontWeightBold,
        backgroundColor: theme.palette.primary.light,
        position: 'absolute',
        right: 0,
    });
});
export var TableBody = styled(MuiTableBody)(function () { return ({}); });
export var TableRow = styled(MuiTableRow)(function (_a) {
    var theme = _a.theme;
    return ({
        height: theme.typography.pxToRem(43),
    });
});
export var TableFooter = styled(MuiTableFooter)(function () { return ({
    position: 'sticky',
    bottom: 0,
}); });
export var TableCell = function (_a) {
    var _b;
    var stickyLefts = _a.stickySizes, _c = _a.stickyIndex, stickyIndex = _c === void 0 ? 0 : _c, greyColor = _a.greyColor, value = _a.value, percentage = _a.percentage, currency = _a.currency, highlightBorder = _a.highlightBorder, sx = _a.sx, decimal = _a.decimal, bold = _a.bold, suffix = _a.suffix, highlight = _a.highlight, warning = _a.warning, props = __rest(_a, ["stickySizes", "stickyIndex", "greyColor", "value", "percentage", "currency", "highlightBorder", "sx", "decimal", "bold", "suffix", "highlight", "warning"]);
    var theme = useTheme();
    var options;
    if (percentage) {
        options = { style: 'percent', minimumFractionDigits: decimal !== null && decimal !== void 0 ? decimal : 1 };
    }
    else if (currency) {
        options = { style: 'currency', currency: currency, minimumFractionDigits: decimal !== null && decimal !== void 0 ? decimal : 1, maximumFractionDigits: decimal };
    }
    else {
        options = { minimumFractionDigits: decimal, maximumFractionDigits: decimal };
    }
    value = typeof value === 'number' ? value.toLocaleString(undefined, options) : value;
    var sxProps = __assign(__assign(__assign({}, sx), { borderRightWidth: highlightBorder ? 4 : undefined, fontWeight: bold || highlight || warning ? 600 : 500 }), (warning && { color: theme.palette.error.main }));
    if (stickyLefts) {
        sxProps = __assign(__assign({}, sxProps), { position: 'sticky', left: (_b = stickyLefts[stickyIndex - 1]) !== null && _b !== void 0 ? _b : 0, minWidth: stickyLefts[stickyIndex], zIndex: 5, boxShadow: 'none', outline: "1px solid ".concat(theme.palette.grey[600]), borderRightWidth: 0 });
    }
    return (_jsx(StyledCell, __assign({}, props, { color: value === '-' || greyColor ? 'greyColor' : props.color, sx: sxProps, className: stickyLefts ? "".concat(props.className, " sticky-cell") : props.className }, { children: props.expandchildren ? (_jsxs(Stack, __assign({ direction: "row", alignItems: "center", position: "relative" }, { children: [_jsxs(Box, __assign({ flex: 1 }, { children: [" ", value !== null && value !== void 0 ? value : props.children] })), _jsx(StyledExpandItem, { children: props.expandchildren })] }))) : (_jsxs(_Fragment, { children: [value !== null && value !== void 0 ? value : props.children, value && suffix, highlight && FLAG] })) })));
};
export var TableContainer = function (_a) {
    var children = _a.children, maxHeight = _a.maxHeight, topScroll = _a.topScroll, props = __rest(_a, ["children", "maxHeight", "topScroll"]);
    var refTop = useRef(null);
    var refBottom = useRef(null);
    var innerRef = useRef(null);
    useEffect(function () {
        var topElement = refTop.current;
        var bottomElement = refBottom.current;
        if (!!topElement && !!bottomElement) {
            topElement.addEventListener('scroll', function () {
                bottomElement.scrollLeft = topElement.scrollLeft;
            });
            bottomElement.addEventListener('scroll', function () {
                topElement.scrollLeft = bottomElement.scrollLeft;
            });
        }
        var setTopElementWidth = function () {
            var _a, _b;
            if (innerRef.current && !!bottomElement) {
                var _c = ((_b = (_a = bottomElement.querySelector(".".concat(tableClasses.root))) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect()) !== null && _b !== void 0 ? _b : {}).width, width = _c === void 0 ? 0 : _c;
                innerRef.current.style.width = "".concat(width, "px");
            }
        };
        setTopElementWidth();
        var element = refTop.current;
        if (element) {
            var marginTop = element.style.marginTop;
            element.style.marginTop = '-1.5px';
            if (element.offsetHeight) {
                element.style.marginTop = marginTop;
            }
        }
        window.addEventListener('resize', setTopElementWidth);
        return function () {
            window.removeEventListener('resize', setTopElementWidth);
        };
    }, []);
    return (_jsxs(Box, __assign({ style: { marginTop: topScroll ? '-1.2rem' : '0' } }, { children: [topScroll && (_jsx(TopScrollContainer, __assign({ ref: refTop }, { children: _jsx(Box, { ref: innerRef, height: "1.2rem" }) }))), _jsx(MuiTableContainer, __assign({ component: StyledTableContainer, elevation: 0, ref: refBottom }, props, { sx: __assign({ maxHeight: maxHeight }, props.sx) }, { children: children }))] })));
};
export var Table = function (props) {
    return _jsx(StyledTable, __assign({ stickyHeader: true }, props));
};
