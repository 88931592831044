var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Grid, Typography, styled } from '@mui/material';
import { useContext, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Currency } from '../../../enum';
import { Autocomplete, FormField } from '../../common';
import { useIsViewOnlyPlan } from '../../report/selectors';
import { BasePanel } from '../common/basePanel';
import { WizardContext } from '../context';
import { useRefreshQueueData } from '../queue/selectors';
import { QueueStatusType } from '../queue/types';
import { RoleTable } from '../supportStaff/roleTable';
import { useClearRoleAPI, useCopyRoleAPI } from '../supportStaff/selectors';
var StyledContainer = styled(Grid)(function (_a) {
    var theme = _a.theme;
    return ({
        width: theme.typography.pxToRem(340),
    });
});
export var CURRENCIES = [
    { name: 'Dollar ($)', id: Currency.Dollar },
    { name: 'Pound (£)', id: Currency.Pound },
    { name: 'Euro (€)', id: Currency.Euro },
    { name: 'Rupee (₹)', id: Currency.Rupee },
];
export var CurrencySelect = function () {
    var t = useTranslation('wizard', { keyPrefix: 'financial' }).t;
    var isViewOnly = useIsViewOnlyPlan();
    var control = useFormContext().control;
    return (_jsxs(StyledContainer, __assign({ container: true, alignItems: "center", columnSpacing: 2 }, { children: [_jsx(Grid, __assign({ item: true, xs: 4.5, textAlign: "right" }, { children: _jsx(Typography, { children: t('selectCurrency') }) })), _jsx(Grid, __assign({ item: true, xs: 7.5 }, { children: _jsx(FormField, __assign({ required: true, name: "currency", control: control, defaultValue: Currency.Dollar }, { children: _jsx(Autocomplete, { disabled: isViewOnly, options: CURRENCIES, getOptionLabel: function (o) { return o.name; } }) })) }))] })));
};
export var StaffSalaries = function () {
    var t = useTranslation(['wizard', 'translation']).t;
    var refreshData = useRefreshQueueData();
    var _a = useCopyRoleAPI(true), copyRoleData = _a[0], copyRole = _a[1], clearCopyRoleData = _a[2];
    var _b = useClearRoleAPI(true), clearRoleData = _b[0], clearRole = _b[1], cleanClearRoleData = _b[2];
    var _c = useContext(WizardContext), handleSave = _c.handleSave, isDirty = _c.methods.formState.isDirty;
    useEffect(function () {
        if (clearRoleData.data || copyRoleData.data) {
            refreshData();
        }
        return function () {
            cleanClearRoleData();
            clearCopyRoleData();
        };
    }, [cleanClearRoleData, clearCopyRoleData, clearRoleData.data, copyRoleData.data, refreshData]);
    var handleClear = function () {
        if (isDirty) {
            return handleSave('submit', function () { return clearRole(); })();
        }
        clearRole();
    };
    var handleCopy = function (source) {
        if (isDirty) {
            return handleSave('submit', function () { return copyRole(source); })();
        }
        copyRole(source);
    };
    return (_jsx(BasePanel, __assign({ title: t('financial.staffSalaries'), description: t('financial.staffSalariesDescription'), statusType: QueueStatusType.Salary, onClear: handleClear, onCopy: handleCopy, errors: [clearRoleData.error, copyRoleData.error], requesting: !!clearRoleData.requesting || !!copyRoleData.requesting, rightContent: _jsx(CurrencySelect, {}) }, { children: _jsx(RoleTable, { disableSupportStaff: true }) })));
};
