var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Paper, Typography, buttonClasses, styled, useTheme } from '@mui/material';
import _ from 'lodash';
import { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import 'swiper/css';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { Autocomplete, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow } from '../common';
import { PERCENTAGES_DETAIL_INDEX } from './constants';
import { useDailyContext } from './context';
import { useDailyAPI, usePlanColor, useReportTimelineOptions } from './selectors';
var FOOTER_KEYS = ['total', 'staffHours'];
var TableNav = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
});
var TableNavButton = styled(Button)(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {
            paddingTop: theme.typography.pxToRem(10),
            paddingBottom: theme.typography.pxToRem(10)
        },
        _b["&.".concat(buttonClasses.root, ".").concat(buttonClasses.disabled)] = {
            borderWidth: 0,
            backgroundColor: 'unset',
            opacity: 0.5,
        },
        _b);
});
var NavTitle = styled(Typography)(function (_a) {
    var theme = _a.theme;
    return ({
        textTransform: 'uppercase',
        fontWeight: theme.typography.fontWeightBold,
    });
});
var StyledJumpTo = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 'auto',
    alignItems: 'center',
});
var StyledAutocomplete = styled(Autocomplete)(function (_a) {
    var theme = _a.theme;
    return ({
        width: theme.typography.pxToRem(220),
        marginLeft: theme.typography.pxToRem(10),
    });
});
var TableNavBarType;
(function (TableNavBarType) {
    TableNavBarType["Week"] = "week";
    TableNavBarType["Day"] = "day";
})(TableNavBarType || (TableNavBarType = {}));
export var TableNavBar = function (_a) {
    var type = _a.type, title = _a.title, onNext = _a.onNext, onPrev = _a.onPrev, disabledNext = _a.disabledNext, disabledPrev = _a.disabledPrev;
    var theme = useTheme();
    var variantBtn = TableNavBarType.Week === type ? 'text' : 'contained';
    var bgcolor = TableNavBarType.Week === type ? theme.palette.grey[400] : theme.palette.text.primary;
    var color = TableNavBarType.Week === type ? theme.palette.text.primary : theme.palette.common.white;
    var fontSizeTitle = TableNavBarType.Week === type ? theme.typography.pxToRem(18) : theme.typography.pxToRem(16);
    return (_jsxs(TableNav, __assign({ bgcolor: bgcolor }, { children: [_jsx(TableNavButton, __assign({ "data-testid": "prev-btn", disabled: disabledPrev, onClick: function () { return onPrev(); }, variant: variantBtn, disableElevation: true }, { children: _jsx(FontAwesomeIcon, { icon: "chevron-left", size: "xl" }) })), _jsx(NavTitle, __assign({ sx: { fontSize: fontSizeTitle }, color: color }, { children: title })), _jsx(TableNavButton, __assign({ "data-testid": "next-btn", disabled: disabledNext, onClick: function () { return onNext(); }, variant: variantBtn, disableElevation: true }, { children: _jsx(FontAwesomeIcon, { icon: "chevron-right", size: "xl" }) }))] })));
};
var DayTableHead = function () {
    var t = useTranslation('report', { keyPrefix: 'daily' }).t;
    return (_jsxs(_Fragment, { children: [_jsxs(TableRow, { children: [_jsx(TableCell, __assign({ component: "td", rowSpan: 2, sx: { width: '6.5rem' } }, { children: "\u00A0" })), _jsx(TableCell, __assign({ component: "td", rowSpan: 2 }, { children: t('workVolume') })), ['staffRequired', 'occupancy', 'asa', 'serviceLevel'].map(function (title) { return (_jsx(TableCell, __assign({ component: "td", colSpan: 2 }, { children: t(title) }), title)); })] }), _jsx(TableRow, { children: _.times(4).map(function (i) { return (_jsxs(Fragment, { children: [_jsx(TableCell, __assign({ component: "td" }, { children: t('model') }), "model-".concat(i)), _jsx(TableCell, __assign({ component: "td" }, { children: t('adjusted') }), "adjusted-".concat(i))] }, i)); }) })] }));
};
var DayTable = function (_a) {
    var time = _a.time, index = _a.index, data = _a.data;
    var swiper = useSwiper();
    var _b = usePlanColor(), bodyColor = _b[1];
    var t = useTranslation(['report', 'translation']).t;
    var renderRowEl = function (arrData) {
        return arrData.map(function (key) { return (_jsxs(TableRow, { children: [_jsx(TableCell, __assign({ component: "th" }, { children: t(key, { defaultValue: key }) })), data[key].map(function (v, idx) { return (_jsx(TableCell, { color: idx & idx % 2 ? bodyColor : '', value: PERCENTAGES_DETAIL_INDEX.includes(idx) && typeof v === 'number' ? v * 100 : v, component: !idx ? 'th' : 'td' }, idx)); })] }, key)); });
    };
    var bodyRowEl = renderRowEl(time);
    var footerRowEl = renderRowEl(FOOTER_KEYS);
    return (_jsxs(_Fragment, { children: [_jsx(TableNavBar, { onNext: function () { return swiper.slideNext(); }, onPrev: function () { return swiper.slidePrev(); }, type: "day", title: t("translation:days.".concat(index)) }), _jsx(TableContainer, __assign({ maxHeight: "32.425rem" }, { children: _jsxs(Table, __assign({ sx: { tableLayout: 'fixed' } }, { children: [_jsx(TableHead, { children: _jsx(DayTableHead, {}) }), _jsx(TableBody, { children: bodyRowEl }), _jsx(TableFooter, { children: footerRowEl })] })) }))] }));
};
export var DetailTable = function () {
    var _a, _b;
    var t = useTranslation('report', { keyPrefix: 'daily' }).t;
    var timelines = useReportTimelineOptions();
    var dailyData = useDailyAPI()[0];
    var _c = useDailyContext('month'), _d = _c[0], periodIndex = _d === void 0 ? 0 : _d, setPeriodIndex = _c[1];
    var slideData = useMemo(function () { var _a; return _.omit((_a = dailyData.data) === null || _a === void 0 ? void 0 : _a.detail, ['time']); }, [(_a = dailyData.data) === null || _a === void 0 ? void 0 : _a.detail]);
    return (_jsxs(_Fragment, { children: [_jsxs(StyledJumpTo, { children: [_jsxs(Typography, __assign({ fontWeight: 600 }, { children: [t('jumpTo'), ":"] })), _jsx(StyledAutocomplete, { options: timelines, value: periodIndex, onChange: setPeriodIndex, disabled: dailyData.requesting })] }), _jsx(TableNavBar, { onNext: function () { return setPeriodIndex(function (curr) {
                    if (curr === void 0) { curr = 0; }
                    return curr + 1;
                }); }, onPrev: function () { return setPeriodIndex(function (curr) {
                    if (curr === void 0) { curr = 0; }
                    return curr - 1;
                }); }, title: (_b = timelines[periodIndex]) === null || _b === void 0 ? void 0 : _b.name, disabledNext: !!dailyData.requesting || periodIndex === timelines.length - 1, disabledPrev: !!dailyData.requesting || !periodIndex, type: "week" }), _jsx(Paper, __assign({ elevation: 0 }, { children: _jsx(Swiper, __assign({ loop: true }, { children: _.map(slideData, function (item, index) {
                        var _a, _b;
                        return (_jsx(SwiperSlide, __assign({ style: { transform: 'none' } }, { children: _jsx(DayTable, { index: index, time: (_b = (_a = dailyData.data) === null || _a === void 0 ? void 0 : _a.detail.time) !== null && _b !== void 0 ? _b : [], data: item }) }), index));
                    }) })) }))] }));
};
