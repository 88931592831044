import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { styled, useTheme } from '@mui/material';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { usePlatformContext } from '../app/selectors';
import { Table, TableBody, TableCell, TableContainer, TableRow } from '../common';
import { SUMMARY_TRAINING_KEY, SUMMARY_TRAINING_KEY_BOTTOM } from './constants';
import { useScenarioSelected, useTrainingSummaryTable } from './selectors';
import { TRAINING_STICKIES, TrainingTableHead } from './trainingTable';
var StyledTableContainer = styled(TableContainer)(function () { return ({
    maxHeight: '75vh',
    position: 'relative',
}); });
var SummaryTableBody = function (_a) {
    var id = _a.id, trainings = _a.trainings, bgColor = _a.bgColor, _b = _a.isSummary, isSummary = _b === void 0 ? false : _b;
    var theme = useTheme();
    var t = useTranslation('report', { keyPrefix: 'training.summary' }).t;
    var rowKeys = isSummary ? SUMMARY_TRAINING_KEY_BOTTOM : SUMMARY_TRAINING_KEY;
    var color = isSummary ? theme.palette.common.white : 'inherit';
    return (_jsx(TableBody, { children: rowKeys.map(function (key) { return (_jsxs(TableRow, { children: [_jsx(TableCell, { stickySizes: TRAINING_STICKIES, sx: { color: color }, color: bgColor, component: isSummary ? 'th' : 'td', align: "left", value: t(key) }), trainings.map(function (training, index) {
                    var _a;
                    var isTotalColumn = !((index + 1) % 13);
                    return (_jsx(TableCell, { greyColor: isTotalColumn && !isSummary, color: bgColor, sx: { color: color }, bold: isSummary, highlightBorder: isTotalColumn && trainings.length - 1 !== index, value: (_a = training[key]) !== null && _a !== void 0 ? _a : '' }, "".concat(index, ".").concat(id, ".").concat(key)));
                })] }, key + id)); }) }, id));
};
export var SummaryTable = function (_a) {
    var scenarioId = _a.scenarioId;
    var t = useTranslation('report', { keyPrefix: 'training.summary' }).t;
    var _b = useTrainingSummaryTable(scenarioId), queues = _b.queues, summary = _b.summary;
    var colors = useScenarioSelected(scenarioId).colors;
    var context = usePlatformContext();
    return (_jsx(StyledTableContainer, { children: _jsxs(Table, { children: [queues.map(function (_a) {
                    var id = _a.id, name = _a.name, trainings = _a.trainings;
                    return (_jsxs(Fragment, { children: [_jsx(TrainingTableHead, { queueName: name, addColumns: ['Total'] }), _jsx(SummaryTableBody, { id: id, trainings: trainings, bgColor: colors[1] })] }, id));
                }), _jsx(TrainingTableHead, { queueName: t('allQueues', { context: context }), addColumns: ['Total'] }), _jsx(SummaryTableBody, { trainings: summary, isSummary: true, bgColor: colors[0] })] }) }));
};
