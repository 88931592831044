var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Link, Typography, styled } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTER_URL } from '../../../router/constants';
import { ConfirmDialog } from '../confirmDialog';
var StyledBar = styled(Box)(function (_a) {
    var theme = _a.theme;
    return ({
        background: theme.palette.primary.light,
        padding: theme.spacing(3, 6),
        display: 'flex',
        justifyContent: 'space-between',
    });
});
export var ExitBar = function (_a) {
    var title = _a.title, isDirty = _a.isDirty, onSave = _a.onSave;
    var _b = useState(false), open = _b[0], setOpen = _b[1];
    var t = useTranslation().t;
    var navigate = useNavigate();
    var handleConfirm = function () {
        setOpen(false);
        onSave === null || onSave === void 0 ? void 0 : onSave();
    };
    return (_jsxs(StyledBar, { children: [_jsx(Typography, __assign({ variant: "h3" }, { children: title })), _jsx(Link, __assign({ component: "button", onClick: function () { return setOpen(true); } }, { children: t('action.exit') })), isDirty ? (_jsx(ConfirmDialog, { open: open, onClose: function () { return setOpen(false); }, title: t('exitAlert.anotherTitle'), message: t('confirmation.confirmExit'), confirmButtonText: "saveContinue", skipSaveButtonText: "skipSave", onConfirm: handleConfirm, onSkipSaveConfirm: function () { return navigate(ROUTER_URL.Home); } })) : (_jsx(ConfirmDialog, { open: open, onClose: function () { return setOpen(false); }, title: t('exitAlert.title'), message: t('confirmation.continueExit'), confirmButtonText: "continue", onConfirm: function () { return navigate(ROUTER_URL.Home); } }))] }));
};
