import _ from 'lodash';
import { useEffect } from 'react';
import { useAPI } from '../../state';
var USER_SESSION_API = '/api/user/me';
export var useUserSessionAPI = function () {
    return useAPI(USER_SESSION_API);
};
export var useUserPermission = function () {
    var data = useAPI(USER_SESSION_API)[0].data;
    return data === null || data === void 0 ? void 0 : data.version.details;
};
export var UserSessionLoader = function () {
    var _a = useUserSessionAPI(), fetchUserSessionData = _a[1];
    useEffect(function () {
        fetchUserSessionData();
    }, [fetchUserSessionData]);
    return null;
};
export var usePlatformContext = function () {
    var _a;
    var data = useUserSessionAPI()[0].data;
    return _.camelCase((_a = data === null || data === void 0 ? void 0 : data.orgs[0]) === null || _a === void 0 ? void 0 : _a.orgType);
};
