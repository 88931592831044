var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { faPenToSquare } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Divider, IconButton, Link, Stack, styled } from '@mui/material';
import _ from 'lodash';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CURRENCY_CODE } from '../../enum';
import { usePlatformContext } from '../app/selectors';
import { ErrorMessageBox, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '../common';
import { useResourcingContext } from '../report/hooks';
import { useExportAPI, useIsViewOnlyPlan, usePrintTableAPI } from '../report/selectors';
import { PLAN_COLORS, WizardStep } from '../wizard/constants';
import { AGENT_ROWS, ANNUAL_REPORT_ROWS, BUDGET_ROWS, CALCULATE_DIFF, COST_ROWS, CURRENCY, HIDE_ROW, INCLUDE_ROLE, PERCENTAGES, PRODUCTION_HOURS_ROWS, SERVICE_ROWS, SUPPORT_STAFF_ROWS, TWO_DECIMAL_VALUE, metricTargetMap, } from './constants';
import { generateVariances, useSelectBaseline, useSelectCurrency, useSelectQueues, useSelectQueuesByName, useSelectRoles, useSelectScenario, useSummaryChunkSize, useSummaryReportAPI, } from './selectors';
import { DialogMode } from './types';
var STICKIES = ['23.75rem', '17rem'];
var FirstCell = styled(TableCell)(function (_a) {
    var theme = _a.theme, paddingLeft = _a["aria-label"];
    return ({
        textAlign: 'left',
        minWidth: theme.typography.pxToRem(380),
        '&.MuiTableCell-root': {
            paddingLeft: paddingLeft,
        },
    });
});
var HeaderCell = function (_a) {
    var _b = _a.title, title = _b === void 0 ? 'Baseline' : _b, handleOpenDialog = _a.handleOpenDialog, highlightBorder = _a.highlightBorder, props = __rest(_a, ["title", "handleOpenDialog", "highlightBorder"]);
    var scenario = useSelectScenario(title);
    var isViewOnly = useIsViewOnlyPlan();
    return (_jsx(TableCell, __assign({ color: PLAN_COLORS[props.headerColor][0], sx: { minWidth: '17rem' }, colSpan: 2, highlightBorder: highlightBorder }, { children: !props.unit ? (_jsxs(Stack, __assign({ direction: "row", alignItems: "center", justifyContent: "center" }, { children: [_jsxs(Stack, __assign({ flex: 1, paddingLeft: "1.75rem" }, { children: [_jsx(Box, { children: title }), !!props.year && _jsxs(Box, { children: ["Year ", props.year] })] })), _jsx(IconButton, __assign({ size: "small", color: "inherit", disabled: isViewOnly, onClick: handleOpenDialog === null || handleOpenDialog === void 0 ? void 0 : handleOpenDialog.bind(null, DialogMode.RenameScenario, scenario) }, { children: _jsx(FontAwesomeIcon, { icon: faPenToSquare }) }))] }))) : (_jsx(_Fragment, { children: props.unit })) })));
};
var AnnualTableHead = function (_a) {
    var title = _a.title, handleOpenDialog = _a.handleOpenDialog, unit = _a.unit;
    var data = useSummaryReportAPI()[0].data;
    var chunkSize = useSummaryChunkSize();
    if (!data)
        return null;
    return (_jsx(TableHead, { children: _jsxs(TableRow, { children: [_jsx(FirstCell, { value: title, stickySizes: STICKIES }), data === null || data === void 0 ? void 0 : data.map(function (plan, indx, arr) { return (_jsx(HeaderCell, { title: plan.name, headerColor: plan.colorIndex, handleOpenDialog: handleOpenDialog, unit: unit, year: chunkSize === arr.length ? 0 : plan.year + 1, highlightBorder: indx + 1 !== arr.length && !((indx + 1) % chunkSize) }, "".concat(plan.name, "_").concat(plan.year))); })] }) }));
};
var ActionCell = function (_a) {
    var handleOpenDialog = _a.handleOpenDialog, title = _a.title, headerColor = _a.headerColor, highlightBorder = _a.highlightBorder;
    var t = useTranslation('report', { keyPrefix: 'annual' }).t;
    var navigate = useNavigate();
    var isViewOnly = useIsViewOnlyPlan();
    var scenario = useSelectScenario(title);
    return (_jsx(TableCell, __assign({ color: PLAN_COLORS[headerColor][1], colSpan: 2, highlightBorder: highlightBorder }, { children: _jsxs(Stack, __assign({ direction: "row", alignItems: "flex-start", spacing: 2, justifyContent: "space-between" }, { children: [_jsx(Link, __assign({ component: "button", disabled: isViewOnly, onClick: function () { return navigate("/wizard/".concat(scenario === null || scenario === void 0 ? void 0 : scenario.basePlanId, "/").concat(scenario === null || scenario === void 0 ? void 0 : scenario.id, "?step=").concat(WizardStep.Review)); } }, { children: t('edit') })), !(scenario === null || scenario === void 0 ? void 0 : scenario.isBaseline) && (_jsxs(_Fragment, { children: [_jsx(Divider, { orientation: "vertical", variant: "middle", flexItem: true }), _jsx(Link, __assign({ component: "button", disabled: isViewOnly, onClick: handleOpenDialog === null || handleOpenDialog === void 0 ? void 0 : handleOpenDialog.bind(null, DialogMode.DeleteScenario, scenario) }, { children: t('delete') })), _jsx(Divider, { orientation: "vertical", variant: "middle", flexItem: true }), _jsx(Link, __assign({ component: "button", disabled: isViewOnly, onClick: handleOpenDialog === null || handleOpenDialog === void 0 ? void 0 : handleOpenDialog.bind(null, DialogMode.SwitchScenario, scenario) }, { children: t('switch') }))] }))] })) })));
};
var ActionRow = function (_a) {
    var handleOpenDialog = _a.handleOpenDialog;
    var data = useSummaryReportAPI()[0].data;
    var chunkSize = useSummaryChunkSize();
    return (_jsxs(TableRow, { children: [_jsx(FirstCell, { stickySizes: STICKIES }), data === null || data === void 0 ? void 0 : data.map(function (plan, indx, arr) { return (_jsx(ActionCell, { title: plan.name, headerColor: plan.colorIndex, handleOpenDialog: handleOpenDialog, highlightBorder: indx + 1 !== arr.length && !((indx + 1) % chunkSize) }, "".concat(plan.name, "_").concat(plan.year))); })] }));
};
var CostRow = function (_a) {
    var currency = _a.currency;
    var queues = useSelectQueues();
    var data = useSummaryReportAPI()[0].data;
    var chunkSize = useSummaryChunkSize();
    return (_jsx(_Fragment, { children: queues === null || queues === void 0 ? void 0 : queues.map(function (queue) { return (_jsxs(TableRow, { children: [_jsx(FirstCell, { value: queue.name, stickySizes: STICKIES }), queue.costPerWork.map(function (value, index, arr) {
                    var _a, _b;
                    return (_jsx(TableCell, { colSpan: 2, decimal: 2, color: PLAN_COLORS[(_b = (_a = data === null || data === void 0 ? void 0 : data[index]) === null || _a === void 0 ? void 0 : _a.colorIndex) !== null && _b !== void 0 ? _b : 0][1], value: value, currency: currency, highlightBorder: index + 1 !== arr.length && !((index + 1) % chunkSize) }, index));
                })] }, queue.name)); }) }));
};
var MainRow = function (_a) {
    var title = _a.title, _b = _a.color, color = _b === void 0 ? '#CCCCCC' : _b, keyItem = _a.keyItem, bold = _a.bold, _c = _a.isHideRow, isHideRow = _c === void 0 ? false : _c, props = __rest(_a, ["title", "color", "keyItem", "bold", "isHideRow"]);
    var t = useTranslation('report', { keyPrefix: 'period' }).t;
    var data = useSummaryReportAPI()[0].data;
    var currency = useSelectCurrency();
    var chunkSize = useSummaryChunkSize();
    var isPercentages = useResourcingContext().isPercentages;
    var baseline = useSelectBaseline();
    if (!data)
        return null;
    return (_jsx(_Fragment, { children: keyItem === 'costPerWork' ? (_jsx(CostRow, { currency: CURRENCY_CODE[currency] })) : (_jsxs(TableRow, { children: [_jsx(FirstCell, __assign({ color: !keyItem ? color : undefined, stickySizes: STICKIES, bold: bold }, { children: _jsxs(Stack, __assign({ direction: "row", justifyContent: "space-between" }, { children: [title, isHideRow && (_jsx(Link, __assign({ component: "button", onClick: props.onShowRow, underline: "none", sx: { fontWeight: 'normal' } }, { children: props.showDetails ? "- ".concat(t('hideDetails')) : "+ ".concat(t('showDetails')) })))] })) })), baseline === null || baseline === void 0 ? void 0 : baseline.map(function (basePlan, year) {
                    return data
                        .slice(chunkSize * year, chunkSize * year + chunkSize)
                        .map(function (plan, index, arr) {
                        var _a;
                        return (_jsx(TableCell, { bold: bold, colSpan: 2, decimal: keyItem && TWO_DECIMAL_VALUE.includes(keyItem) ? 2 : 0, color: (_a = PLAN_COLORS[plan === null || plan === void 0 ? void 0 : plan.colorIndex]) === null || _a === void 0 ? void 0 : _a[1], value: keyItem ? plan[keyItem] : undefined, percentage: keyItem && PERCENTAGES.includes(keyItem), currency: keyItem && CURRENCY.includes(keyItem) ? CURRENCY_CODE[currency] : undefined, highlightBorder: year !== baseline.length - 1 && index === arr.length - 1, expandchildren: keyItem && CALCULATE_DIFF.includes(keyItem)
                                ? generateVariances(basePlan[keyItem], plan[keyItem], isPercentages)
                                : undefined }, "".concat(plan.name, "_").concat(plan.year)));
                    });
                })] })) }));
};
var RoleRow = function (_a) {
    var keyItem = _a.keyItem;
    var roles = useSelectRoles(keyItem);
    var currency = useSelectCurrency();
    var isPercentages = useResourcingContext().isPercentages;
    var data = useSummaryReportAPI()[0].data;
    if (!data)
        return null;
    return (_jsx(_Fragment, { children: roles === null || roles === void 0 ? void 0 : roles.map(function (role) { return (_jsxs(TableRow, { children: [_jsx(FirstCell, { value: role.role, stickySizes: STICKIES }), role.value.map(function (values, year, arr) {
                    return values.map(function (value, index) {
                        var _a, _b;
                        return (_jsx(TableCell, { colSpan: 2, value: value, decimal: TWO_DECIMAL_VALUE.includes(keyItem) ? 2 : 0, color: (_b = PLAN_COLORS[(_a = data[index]) === null || _a === void 0 ? void 0 : _a.colorIndex]) === null || _b === void 0 ? void 0 : _b[1], currency: CURRENCY.includes(keyItem) ? CURRENCY_CODE[currency] : undefined, highlightBorder: year !== arr.length - 1 && index === values.length - 1, expandchildren: CALCULATE_DIFF.includes(keyItem) ? generateVariances(values[0], value, isPercentages) : undefined }, index));
                    });
                })] }, role.role)); }) }));
};
var QueueCell = function (_a) {
    var queueName = _a.queueName, keys = _a.keys, _b = _a.hasSubItem, hasSubItem = _b === void 0 ? false : _b;
    var queueData = useSelectQueuesByName(queueName);
    var chunkSize = useSummaryChunkSize();
    var isPercentages = useResourcingContext().isPercentages;
    var data = useSummaryReportAPI()[0].data;
    if (!data || !queueData)
        return null;
    return (_jsx(_Fragment, { children: hasSubItem ? (_jsxs(_Fragment, { children: [_jsxs(TableRow, { children: [_jsx(FirstCell, { value: queueName, bold: true, stickySizes: STICKIES }), queueData === null || queueData === void 0 ? void 0 : queueData.flat().map(function (q, index, arr) {
                            var _a, _b;
                            return (_jsx(TableCell, { colSpan: 2, color: (_b = PLAN_COLORS[(_a = data[index]) === null || _a === void 0 ? void 0 : _a.colorIndex]) === null || _b === void 0 ? void 0 : _b[1], highlightBorder: index + 1 !== arr.length && !((index + 1) % chunkSize) }, index));
                        })] }), keys.map(function (key) { return (_jsxs(TableRow, { children: [_jsx(FirstCell, { value: ANNUAL_REPORT_ROWS[key], "aria-label": "2rem", stickySizes: STICKIES }), queueData === null || queueData === void 0 ? void 0 : queueData.map(function (queues, year, arr) {
                            return queues.map(function (queue, index) {
                                var _a, _b, _c, _d;
                                return (_jsxs(React.Fragment, { children: [_jsx(TableCell, { bold: !queue.metricTarget, color: (_b = PLAN_COLORS[(_a = data[index]) === null || _a === void 0 ? void 0 : _a.colorIndex]) === null || _b === void 0 ? void 0 : _b[1], sx: { minWidth: '6.4rem' }, value: queue[key], percentage: PERCENTAGES.includes(key), decimal: TWO_DECIMAL_VALUE.includes(key) ? 2 : 0, colSpan: key === 'serviceLevelForecast' ? 1 : 2, highlight: queue.metricTarget && queue.metricTarget === metricTargetMap[key], highlightBorder: key !== 'serviceLevelForecast' && year !== arr.length - 1 && index === queues.length - 1 }), key === 'serviceLevelForecast' && (_jsx(TableCell, { sx: { minWidth: '6.4rem' }, value: queue.threshold, suffix: ' sec', color: (_d = PLAN_COLORS[(_c = data[index]) === null || _c === void 0 ? void 0 : _c.colorIndex]) === null || _d === void 0 ? void 0 : _d[1], highlightBorder: year !== arr.length - 1 && index === queues.length - 1, highlight: queue.metricTarget === metricTargetMap[key] }))] }, index));
                            });
                        })] }, key)); })] })) : (keys.map(function (key) { return (_jsxs(TableRow, { children: [_jsx(FirstCell, { value: queueName, stickySizes: STICKIES }), queueData === null || queueData === void 0 ? void 0 : queueData.map(function (queues, year, arr) {
                    return queues.map(function (queue, index) {
                        var _a, _b;
                        return (_jsx(TableCell, { value: queue[key], color: (_b = PLAN_COLORS[(_a = data[index]) === null || _a === void 0 ? void 0 : _a.colorIndex]) === null || _b === void 0 ? void 0 : _b[1], colSpan: 2, percentage: PERCENTAGES.includes(key), decimal: TWO_DECIMAL_VALUE.includes(key) ? 2 : 0, highlightBorder: year !== arr.length - 1 && index === queues.length - 1, expandchildren: CALCULATE_DIFF.includes(key)
                                ? generateVariances(queues[0][key], queue[key], isPercentages)
                                : undefined }, index));
                    });
                })] }, key)); })) }));
};
var ExpandRow = function (_a) {
    var data = _a.data, keyRow = _a.keyRow;
    var currency = useSelectCurrency();
    var chunkSize = useSummaryChunkSize();
    var rows = useMemo(function () {
        return data.reduce(function (acc, curr) {
            var agents = _.chain([curr === null || curr === void 0 ? void 0 : curr.defQueue, curr === null || curr === void 0 ? void 0 : curr.nonDefQueue])
                .flatten()
                .sortBy('order')
                .value()
                .map(function (_a) {
                var name = _a.name, value = _a.agentsSalaries;
                return ({ name: name, value: value });
            });
            var staff = curr.staffSalaries
                .filter(function (_a) {
                var role = _a.role;
                return role !== 'Agent';
            })
                .map(function (_a) {
                var name = _a.role, value = _a.value;
                return ({ name: name, value: value });
            });
            acc.push(keyRow === 'agentsSalaries' ? agents : staff);
            return acc;
        }, []);
    }, [data, keyRow]);
    return (_jsx(_Fragment, { children: rows[0].map(function (_a, index) {
            var name = _a.name;
            return (_jsxs(TableRow, { children: [_jsx(FirstCell, { value: name, "aria-label": "2rem", stickySizes: STICKIES }), rows.map(function (row, i, arr) {
                        var _a;
                        return (_jsx(TableCell, { value: row[index].value, colSpan: 2, color: (_a = PLAN_COLORS[data[i].colorIndex]) === null || _a === void 0 ? void 0 : _a[1], decimal: 0, currency: CURRENCY_CODE[currency], highlightBorder: i + 1 !== arr.length && !((i + 1) % chunkSize) }, "".concat(index, "-").concat(i)));
                    })] }, name));
        }) }));
};
var QueueRow = function (_a) {
    var _b, _c;
    var keys = _a.keys, constraintType = _a.constraintType, both = _a.both;
    var data = useSummaryReportAPI()[0].data;
    var _d = useState(HIDE_ROW), showDetails = _d[0], setShowDetails = _d[1];
    var onShowRow = function (item) {
        setShowDetails(function (prev) { return (prev.includes(item) ? prev.filter(function (i) { return i !== item; }) : __spreadArray(__spreadArray([], prev, true), [item], false)); });
    };
    if (!keys || !data)
        return null;
    var queueData;
    if (constraintType === 'defQueue') {
        queueData = (_b = data[0]) === null || _b === void 0 ? void 0 : _b.defQueue;
    }
    else if (constraintType === 'nonDefQueue') {
        queueData = (_c = data[0]) === null || _c === void 0 ? void 0 : _c.nonDefQueue;
    }
    return (_jsxs(React.Fragment, { children: [(queueData === null || queueData === void 0 ? void 0 : queueData.length) ? _jsx(MainRow, { title: ANNUAL_REPORT_ROWS[constraintType] }) : null, queueData === null || queueData === void 0 ? void 0 : queueData.map(function (queue) { return _jsx(QueueCell, { queueName: queue.name, keys: keys, hasSubItem: !both }, queue.name); }), constraintType === 'both' &&
                keys.map(function (key) {
                    return INCLUDE_ROLE.includes(key) ? (_jsx(RoleRow, { keyItem: key }, key)) : (_jsxs(React.Fragment, { children: [_jsx(MainRow, { title: ANNUAL_REPORT_ROWS[key], keyItem: key, bold: true, isHideRow: HIDE_ROW.includes(key), showDetails: showDetails.includes(key), onShowRow: function () { return onShowRow(key); } }), showDetails.includes(key) && _jsx(ExpandRow, { data: data, keyRow: key })] }, key));
                })] }));
};
var AnnualTableBody = function (_a) {
    var handleOpenDialog = _a.handleOpenDialog, headerRows = _a.headerRows, _b = _a.both, both = _b === void 0 ? true : _b;
    return (_jsxs(TableBody, { children: [handleOpenDialog && _jsx(ActionRow, { handleOpenDialog: handleOpenDialog }), Object.keys(headerRows).map(function (key) { return (_jsx(React.Fragment, { children: _jsx(QueueRow, { keys: headerRows[key], constraintType: key, both: both }) }, key)); })] }));
};
var AnnualTable = function (props) {
    var summaryData = useSummaryReportAPI()[0];
    if (!summaryData.data)
        return null;
    return (_jsx(TableContainer, __assign({ topScroll: true }, { children: _jsx(Table, { children: props.children }) })));
};
export var ResourcingTable = function (_a) {
    var handleOpenDialog = _a.handleOpenDialog;
    var t = useTranslation('report', { keyPrefix: 'annual' }).t;
    var summaryData = useSummaryReportAPI()[0];
    var printData = usePrintTableAPI()[0];
    var exportData = useExportAPI()[0];
    return (_jsxs(_Fragment, { children: [_jsx(ErrorMessageBox, { errors: [summaryData.error, printData.error, exportData.error] }), _jsxs(AnnualTable, { children: [_jsx(AnnualTableHead, { handleOpenDialog: handleOpenDialog, title: t('serviceOrProductivity') }), _jsx(AnnualTableBody, { handleOpenDialog: handleOpenDialog, headerRows: SERVICE_ROWS, both: false }), _jsx(AnnualTableHead, { title: t('productionHours'), unit: t('hours') }), _jsx(AnnualTableBody, { headerRows: PRODUCTION_HOURS_ROWS }), _jsx(AnnualTableHead, { title: t('agentRequirement'), unit: "# ".concat(t('ofAgents')) }), _jsx(AnnualTableBody, { headerRows: AGENT_ROWS }), _jsx(AnnualTableHead, { title: t('supportStaff'), unit: "# ".concat(t('ofStaff')) }), _jsx(AnnualTableBody, { headerRows: SUPPORT_STAFF_ROWS })] })] }));
};
export var FinancialTable = function (_a) {
    var handleOpenDialog = _a.handleOpenDialog;
    var t = useTranslation('report', { keyPrefix: 'annual' }).t;
    var summaryData = useSummaryReportAPI()[0];
    var printData = usePrintTableAPI()[0];
    var exportData = useExportAPI()[0];
    var context = usePlatformContext();
    return (_jsxs(_Fragment, { children: [_jsx(ErrorMessageBox, { errors: [summaryData.error, printData.error, exportData.error] }), _jsxs(AnnualTable, { children: [_jsx(AnnualTableHead, { handleOpenDialog: handleOpenDialog, title: t('staffBudget') }), _jsx(AnnualTableBody, { headerRows: BUDGET_ROWS }), _jsx(AnnualTableHead, { title: t('costPerWorkType', { context: context }), unit: t('cost') }), _jsx(AnnualTableBody, { headerRows: COST_ROWS })] })] }));
};
