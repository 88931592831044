var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Divider, Typography, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Outlet } from '../common';
import { Footer } from './footer';
import { Header } from './header';
var AppContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
});
var Main = styled(Box)(function (_a) {
    var theme = _a.theme;
    return ({
        flex: 'auto',
        minHeight: 0,
        paddingTop: theme.typography.pxToRem(60),
    });
});
var StyledFluidContainer = styled(AppContainer)(function (_a) {
    var theme = _a.theme;
    return ({
        paddingTop: theme.typography.pxToRem(120),
        backgroundColor: theme.palette.primary.light,
        alignItems: 'center',
        textAlign: 'center',
        gap: theme.spacing(4),
    });
});
var ImageLogo = styled('img')(function (_a) {
    var theme = _a.theme;
    return ({
        width: theme.typography.pxToRem(250),
    });
});
var PageTitle = styled(Typography)(function (_a) {
    var theme = _a.theme;
    return ({
        width: 'fit-content',
        margin: theme.spacing(2, 0),
    });
});
export var AppLayout = function () {
    return (_jsxs(AppContainer, { children: [_jsx(Header, {}), _jsx(Main, __assign({ component: "main" }, { children: _jsx(Outlet, {}) })), _jsx(Divider, {}), _jsx(Footer, {})] }));
};
export var FluidContainer = function (props) {
    var t = useTranslation().t;
    return (_jsxs(StyledFluidContainer, { children: [_jsx(ImageLogo, { src: "/assets/images/logo.png", alt: "logo" }), _jsxs(PageTitle, __assign({ variant: "h1" }, { children: [t('layout.workforceInsights'), " ", _jsx("br", {}), " ", t('layout.capacityPlanning')] })), props.children] }));
};
