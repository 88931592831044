var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormErrorText } from '../../common';
import { useIsViewOnlyPlan } from '../../report/selectors';
import { BasePanel } from '../common/basePanel';
import { QueuePanel } from '../common/queuePanel';
import { StepContainer, StepPanel } from '../common/stepContainer';
import { WizardStep } from '../constants';
import { useRefreshQueueData } from '../queue/selectors';
import { QueueStatusType } from '../queue/types';
import { usePlanType, useWizardParams } from '../selectors';
import { AddShrinkageFactor } from './addShrinkageFactor';
import { useClearShrinkageAPI, useCopyShrinkageAPI } from './selectors';
import { ShrinkageTable } from './table';
export var ShrinkageContent = function (props) {
    var _a;
    var planType = usePlanType();
    var step = useWizardParams().step;
    var addColumns = step === WizardStep.Review ? ['Average'] : [];
    var t = useTranslation('wizard', { keyPrefix: 'shrinkage' }).t;
    var isViewOnly = useIsViewOnlyPlan();
    var errors = useFormContext().formState.errors;
    return (_jsxs(_Fragment, { children: [_jsx(ShrinkageTable, __assign({ disabled: isViewOnly }, props, { addColumns: addColumns })), !!((_a = errors.shrinkages) === null || _a === void 0 ? void 0 : _a.root) && _jsx(FormErrorText, { message: t('overError', { planType: planType }) })] }));
};
export var ShrinkageStep = function () {
    var refreshData = useRefreshQueueData();
    var _a = useCopyShrinkageAPI(), copyShrinkageData = _a[0], copyShrinkage = _a[1], clearCopyShrinkageData = _a[2];
    var _b = useClearShrinkageAPI(), clearShrinkageData = _b[0], clearShrinkage = _b[1], cleanClearShrinkageData = _b[2];
    var t = useTranslation('wizard', { keyPrefix: 'shrinkage' }).t;
    useEffect(function () {
        if (copyShrinkageData.data || clearShrinkageData.data) {
            refreshData();
        }
        return function () {
            clearCopyShrinkageData();
            cleanClearShrinkageData();
        };
    }, [cleanClearShrinkageData, clearCopyShrinkageData, clearShrinkageData.data, copyShrinkageData.data, refreshData]);
    return (_jsx(StepContainer, __assign({ leftContent: _jsx(QueuePanel, {}) }, { children: _jsxs(StepPanel, { children: [_jsx(BasePanel, __assign({ onClear: clearShrinkage, onCopy: copyShrinkage, statusType: QueueStatusType.Shrinkages, errors: [copyShrinkageData.error, clearShrinkageData.error], requesting: !!copyShrinkageData.requesting || !!clearShrinkageData.requesting, title: t('fullName') }, { children: _jsx(ShrinkageContent, {}) })), _jsx(AddShrinkageFactor, {})] }) })));
};
