import { PlanType } from '../../enum';
export var PLAN_TYPE = [
    { value: PlanType.Monthly, name: 'Monthly' },
    { value: PlanType.Weekly, name: 'Weekly' },
];
export var PERIOD_LENGTH = [
    { value: 12, name: '12 months' },
    { value: 24, name: '24 months' },
    { value: 36, name: '36 months' },
];
export var MAX_FILE_SIZE = 10485760; // 10MB
