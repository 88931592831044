import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useIsViewOnlyPlanAPI } from '../report/selectors';
import { useQueueAPI, useQueuesAPI, useSelectedQueue, useWorkTypeAPI } from './queue/selectors';
import { usePlanAPI, useWizardParams } from './selectors';
export var WizardLoader = function () {
    var _a = usePlanAPI(), planData = _a[0], fetchPlanData = _a[1], clearPlanData = _a[2];
    var _b = useIsViewOnlyPlanAPI(), fetchIsViewOnlyPlan = _b[1], clearIsViewOnlyPlan = _b[2];
    var _c = useWorkTypeAPI(), clearWorkType = _c[2];
    var _d = useQueuesAPI(), queuesData = _d[0], fetchQueues = _d[1], clearQueuesData = _d[2];
    var _e = useQueueAPI(), fetchQueue = _e[1], clearQueueData = _e[2];
    var navigate = useNavigate();
    var _f = useWizardParams(), id = _f.id, step = _f.step;
    var queue = useSelectedQueue();
    useEffect(function () {
        fetchPlanData();
        return function () {
            clearPlanData();
            clearWorkType();
        };
    }, [clearPlanData, clearWorkType, fetchPlanData]);
    useEffect(function () {
        fetchIsViewOnlyPlan();
        return clearIsViewOnlyPlan;
    }, [clearIsViewOnlyPlan, fetchIsViewOnlyPlan]);
    useEffect(function () {
        fetchQueues();
        return clearQueuesData;
    }, [clearQueuesData, fetchQueues]);
    useEffect(function () {
        fetchQueue();
        return clearQueueData;
    }, [clearQueueData, fetchQueue]);
    useEffect(function () {
        if (planData.data && !id) {
            navigate({
                pathname: "/wizard/".concat(planData.data.basePlanId, "/").concat(planData.data.id),
                search: window.location.search,
            }, { replace: true });
        }
    }, [id, navigate, planData.data]);
    useEffect(function () {
        var _a;
        if (((_a = queuesData.data) === null || _a === void 0 ? void 0 : _a.length) && !queue) {
            navigate({
                pathname: window.location.pathname,
                search: "queueId=".concat(queuesData.data[0].id, "&step=").concat(step),
            }, { replace: true });
        }
    }, [navigate, queue, queuesData.data, step]);
    return null;
};
