var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { styled, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormField, NativeNumberInput, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, } from '../common';
import { useReportTimeline } from '../daily/selectors';
import { StyledFirstCell, StyledTableCell } from '../period/periodTable';
import { useIsViewOnlyPlan } from '../report/selectors';
import { PLAN_COLORS } from '../wizard/constants';
import { MONTH_OF_YEAR, TimelineType } from '../wizard/selectors';
import { TRAINING_KEY, TRAINING_KEY_BOTTOM, TRAINING_KEY_EDITABLE } from './constants';
export var TRAINING_STICKIES = ['10.75rem'];
export var QueueNameTableCell = styled(StyledFirstCell)(function (_a) {
    var theme = _a.theme;
    return ({
        borderLeft: 'unset !important',
        minWidth: theme.typography.pxToRem(300),
    });
});
export var TrainingTableHead = function (_a) {
    var queueName = _a.queueName, _b = _a.addColumns, addColumns = _b === void 0 ? [] : _b;
    var timelines = useReportTimeline.apply(void 0, __spreadArray([true, true], addColumns, false));
    var totalColumn = MONTH_OF_YEAR + addColumns.length;
    return (_jsx(TableHead, { children: timelines.map(function (_a, rowIdx) {
            var titles = _a[0], type = _a[1];
            return (_jsxs(TableRow, { children: [!rowIdx && _jsx(QueueNameTableCell, { rowSpan: 2, stickySizes: TRAINING_STICKIES, value: queueName }), titles === null || titles === void 0 ? void 0 : titles.map(function (title, cellIdx, arr) { return (_jsx(StyledTableCell, { colSpan: type === TimelineType.Years ? totalColumn : 1, highlightBorder: cellIdx !== arr.length - 1 && (type === TimelineType.Years || !((cellIdx + 1) % totalColumn)), value: title }, cellIdx)); })] }, type));
        }) }));
};
var TrainingTableInputCell = function (_a) {
    var inputKey = _a.inputKey, trainingItem = _a.trainingItem, control = _a.control, setValue = _a.setValue, disabled = _a.disabled;
    var getMaxValueInput = function (key) {
        if (key === 'trainingLength') {
            return 24;
        }
        if (key === 'forecastedGraduationRate') {
            return 100;
        }
        return 10000;
    };
    return (_jsx(FormField, __assign({ disableHelperText: true, required: true, name: "trainings.".concat(trainingItem.month, ".").concat(inputKey), control: control }, { children: _jsx(NativeNumberInput, { disabled: disabled, placeholder: inputKey === 'forecastedGraduationRate' ? '%' : '', min: inputKey === 'trainingLength' ? 0 : 1, max: getMaxValueInput(inputKey), setValue: setValue }) })));
};
var TrainingTableDisplayCell = function (_a) {
    var _b;
    var inputKey = _a.inputKey, trainingItem = _a.trainingItem;
    var attritionValue = Number(trainingItem['attrition']) * 100;
    var value = typeof trainingItem[inputKey] === 'number'
        ? (_b = trainingItem[inputKey]) === null || _b === void 0 ? void 0 : _b.toLocaleString('en-US')
        : trainingItem[inputKey];
    return (_jsx(_Fragment, { children: inputKey === 'attrition' ? _jsx("span", { children: "".concat(attritionValue.toFixed(2), "%") }) : _jsx("span", { children: "".concat(value !== null && value !== void 0 ? value : '') }) }));
};
var TrainingTableBody = function (_a) {
    var trainings = _a.trainings, bgColor = _a.bgColor, control = _a.control, setValue = _a.setValue, disabled = _a.disabled;
    var theme = useTheme();
    var t = useTranslation('report', { keyPrefix: 'training' }).t;
    return (_jsx(TableBody, { children: TRAINING_KEY.map(function (key, index) {
            var isBottomRow = TRAINING_KEY_BOTTOM.includes(key);
            var isEditRow = TRAINING_KEY_EDITABLE.includes(key);
            var color = isBottomRow ? theme.palette.common.white : 'inherit';
            return (_jsxs(TableRow, { children: [_jsx(TableCell, { stickySizes: TRAINING_STICKIES, sx: { color: color }, color: isBottomRow ? bgColor[0] : bgColor[1], component: "th", align: "left", value: t(key) }), trainings.map(function (cell, cellIdx) {
                        return (_jsx(TableCell, __assign({ sx: { color: color }, color: isBottomRow ? bgColor[0] : isEditRow ? bgColor[1] : theme.palette.common.white, highlightBorder: !((cellIdx + 1) % 12) && trainings.length - 1 !== cellIdx }, { children: isEditRow ? (_jsx(TrainingTableInputCell, { disabled: disabled, inputKey: key, trainingItem: cell, setValue: setValue, control: control })) : (_jsx(TrainingTableDisplayCell, { inputKey: key, trainingItem: cell })) }), "".concat(cell.id).concat(index).concat(cellIdx)));
                    })] }, key));
        }) }));
};
export var TrainingTable = function (_a) {
    var currentScenario = _a.currentScenario, trainings = _a.trainings, control = _a.control, setValue = _a.setValue;
    var _b = currentScenario.colorIndex, colorIndex = _b === void 0 ? 0 : _b;
    var isViewOnly = useIsViewOnlyPlan();
    return (_jsx(TableContainer, { children: _jsxs(Table, { children: [_jsx(TrainingTableHead, { queueName: currentScenario.isBaseline ? 'Baseline' : currentScenario.name }), _jsx(TrainingTableBody, { trainings: trainings, bgColor: PLAN_COLORS[colorIndex], control: control, setValue: setValue, disabled: isViewOnly })] }) }));
};
