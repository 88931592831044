var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Divider, Link, styled } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DownloadTemplateDialog } from '../downloadTemplate';
var PUBLISH_YEAR = 2024;
export var SUPPORT_EMAIL = 'support@cinareo.com';
var USER_GUIDE_URL = 'https://www.cinareo.com/userguide-may-2024-membersonlyaccess-protected';
var PRIVACY_URL = 'https://www.cinareo.com/privacy';
export var TERMS_OF_USE_URL = 'https://www.cinareo.com/terms-of-use';
var StyledFooter = styled(Box)(function (_a) {
    var theme = _a.theme;
    return ({
        flex: '0 0 auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: theme.typography.pxToRem(20),
        background: theme.palette.common.white,
    });
});
var FooterContent = styled(Box)(function (_a) {
    var theme = _a.theme;
    return ({
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(2),
        fontSize: theme.typography.caption.fontSize,
    });
});
var NeedHelp = styled(Box)(function (_a) {
    var theme = _a.theme;
    return ({
        fontWeight: theme.typography.fontWeightBold,
    });
});
var Copyright = styled(Box)(function (_a) {
    var theme = _a.theme;
    return ({
        color: theme.palette.text.secondary,
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(2),
    });
});
var ImageLogo = styled('img')(function (_a) {
    var theme = _a.theme;
    return ({
        height: theme.typography.pxToRem(16),
    });
});
export var Footer = function () {
    var t = useTranslation('translation', { keyPrefix: 'footer' }).t;
    var _a = useState(false), openDownloadTemplateDialog = _a[0], setOpenDownloadTemplateDialog = _a[1];
    var currentYear = new Date().getFullYear();
    var year = currentYear > PUBLISH_YEAR ? "".concat(PUBLISH_YEAR, "-").concat(currentYear) : PUBLISH_YEAR;
    var isEnableDownload = process.env.REACT_APP_DOWNLOAD_TEMPLATE !== 'false';
    return (_jsxs(StyledFooter, __assign({ component: "footer" }, { children: [_jsxs(FooterContent, { children: [_jsx(NeedHelp, { children: t('needHelp') }), _jsxs(Box, { children: [t('contact'), " ", _jsx(Link, __assign({ href: "mailto:".concat(SUPPORT_EMAIL) }, { children: SUPPORT_EMAIL }))] }), _jsx(Divider, { orientation: "vertical", variant: "middle", flexItem: true }), _jsx(Link, __assign({ href: USER_GUIDE_URL, target: "_blank" }, { children: t('viewUserGuide') })), isEnableDownload && (_jsxs(_Fragment, { children: [_jsx(Divider, { orientation: "vertical", variant: "middle", flexItem: true }), _jsx(Link, __assign({ component: "button", onClick: function () { return setOpenDownloadTemplateDialog(true); } }, { children: t('downloadTemplate') }))] }))] }), _jsxs(FooterContent, { children: [_jsx(Link, __assign({ href: TERMS_OF_USE_URL, target: "_blank" }, { children: t('termsOfUse') })), _jsx(Divider, { orientation: "vertical", variant: "middle", flexItem: true }), _jsx(Link, __assign({ href: PRIVACY_URL, target: "_blank" }, { children: t('privacyPolicy') })), _jsx(Divider, { orientation: "vertical", variant: "middle", flexItem: true }), _jsx(Copyright, { children: t('copyright', { year: year }) }), _jsx(ImageLogo, { src: "/assets/images/logo.png" })] }), _jsx(DownloadTemplateDialog, { open: openDownloadTemplateDialog, onClose: function () { return setOpenDownloadTemplateDialog(false); } })] })));
};
