var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Link } from '@mui/material';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Autocomplete, ConfirmDialog, FormField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, } from '../../common';
import { DAY_NAMES, GENERAL_MONTH, WizardStep } from '../constants';
import { WizardContext } from '../context';
import { useQueueAPI } from '../queue/selectors';
import { usePlanAPI, useWizardParams } from '../selectors';
import { CLOSED, DEFAULT_TIME_INTERVAL, FULL_TIME, START_OF_DATE, selectOpenTimeOptions, useCloseTimeOptions, useDeleteWapAPI, useEditableMonth, useExceptionTitle, useWATGroup, } from './selectors';
var OPEN_CLOSE_TIME = ['openTime', 'closeTime'];
var OpenTimeSelect = function (_a) {
    var month = _a.month, control = _a.control, setValue = _a.setValue, day = _a.day;
    var options = selectOpenTimeOptions(DEFAULT_TIME_INTERVAL);
    var handleChange = function (value) {
        if (value === CLOSED) {
            setValue === null || setValue === void 0 ? void 0 : setValue("items.month".concat(month, ".").concat(day, ".closeTime"), CLOSED);
            return;
        }
        if (value === FULL_TIME) {
            setValue === null || setValue === void 0 ? void 0 : setValue("items.month".concat(month, ".").concat(day, ".openTime"), START_OF_DATE);
            setValue === null || setValue === void 0 ? void 0 : setValue("items.month".concat(month, ".").concat(day, ".closeTime"), START_OF_DATE, { shouldValidate: true });
            return false;
        }
        setValue === null || setValue === void 0 ? void 0 : setValue("items.month".concat(month, ".").concat(day, ".closeTime"), null);
    };
    return (_jsx(FormField, __assign({ required: true, name: "items.month".concat(month, ".").concat(day, ".openTime"), control: control, disableHelperText: true }, { children: _jsx(Autocomplete, { options: options, onChange: handleChange, "data-testid": "timeTableSelect".concat(month).concat(day) }) })));
};
var CloseTimeSelect = function (_a) {
    var _b, _c;
    var month = _a.month, control = _a.control, watch = _a.watch, day = _a.day;
    var _d = watch(), timeInterval = _d.timeInterval, items = _d.items;
    var openTime = ((_c = (_b = items === null || items === void 0 ? void 0 : items["month".concat(month)]) === null || _b === void 0 ? void 0 : _b[day]) !== null && _c !== void 0 ? _c : {}).openTime;
    var options = useCloseTimeOptions(timeInterval, openTime);
    return (_jsx(FormField, __assign({ required: true, name: "items.month".concat(month, ".").concat(day, ".closeTime"), control: control, disableHelperText: true }, { children: _jsx(Autocomplete, { options: options }) })));
};
var WATTableBody = function (props) {
    var t = useTranslation('wizard', { keyPrefix: 'wapTime' }).t;
    var editable = useEditableMonth(props.month);
    return (_jsx(TableBody, { children: OPEN_CLOSE_TIME.map(function (type) { return (_jsxs(TableRow, { children: [_jsx(TableCell, __assign({ component: "th" }, { children: t(type) })), DAY_NAMES === null || DAY_NAMES === void 0 ? void 0 : DAY_NAMES.map(function (title, day) {
                    var _a;
                    var Component = type === 'openTime' ? OpenTimeSelect : CloseTimeSelect;
                    var value = (_a = props.data[day]) === null || _a === void 0 ? void 0 : _a[type];
                    return (_jsx(TableCell, __assign({ padding: "none" }, { children: editable ? _jsx(Component, __assign({}, props, { day: day })) : value }), title));
                })] }, type)); }) }));
};
export var WorkArrivalTimeTable = function (props) {
    var _a;
    var month = props.month;
    var _b = useExceptionTitle(month), monthTitleNode = _b[0], monthTitleText = _b[1];
    var planData = usePlanAPI()[0];
    var _c = useState(false), confirmDeleteOpen = _c[0], setConfirmDeleteOpen = _c[1];
    var t = useTranslation('wizard', { keyPrefix: 'wapTime.deleteTable' }).t;
    var _d = useDeleteWapAPI(), deleteWAP = _d[1];
    var queueData = useQueueAPI()[0];
    var watGroup = useWATGroup();
    var step = useWizardParams().step;
    var _e = useContext(WizardContext), handleSave = _e.handleSave, isDirty = _e.methods.formState.isDirty;
    var handleDelete = function () {
        var _a, _b, _c, _d, _e;
        if (watGroup["month".concat(month)]) {
            if (isDirty) {
                setConfirmDeleteOpen(false);
                return handleSave('submit', function () { var _a; return deleteWAP(undefined, { queueId: (_a = queueData.data) === null || _a === void 0 ? void 0 : _a.id, month: month }); })();
            }
            deleteWAP(undefined, { queueId: (_a = queueData.data) === null || _a === void 0 ? void 0 : _a.id, month: month });
        }
        else {
            var items_1 = (_c = (_b = props.getValues) === null || _b === void 0 ? void 0 : _b.call(props, 'items')) !== null && _c !== void 0 ? _c : {};
            if (isDirty) {
                setConfirmDeleteOpen(false);
                handleSave('submit', function () { return delete items_1["month".concat(month)]; })();
                return (_d = props.setValue) === null || _d === void 0 ? void 0 : _d.call(props, "items", items_1);
            }
            delete items_1["month".concat(month)];
            (_e = props.setValue) === null || _e === void 0 ? void 0 : _e.call(props, "items", items_1);
        }
        setConfirmDeleteOpen(false);
    };
    return (_jsxs(Box, { children: [((_a = planData.data) === null || _a === void 0 ? void 0 : _a.isBaseline) && month !== GENERAL_MONTH && step !== WizardStep.Review && (_jsx(Link, __assign({ sx: { float: 'right' }, "data-testid": "timeTableLink", component: "button", type: "button", onClick: function () { return setConfirmDeleteOpen(true); } }, { children: t('label') }))), _jsx(TableContainer, { children: _jsxs(Table, __assign({ sx: { tableLayout: 'fixed', minWidth: '60rem' } }, { children: [_jsx(TableHead, { children: _jsxs(TableRow, { children: [_jsx(TableCell, { children: monthTitleNode }), DAY_NAMES === null || DAY_NAMES === void 0 ? void 0 : DAY_NAMES.map(function (title) { return _jsx(TableCell, { children: title }, title); })] }) }), _jsx(WATTableBody, __assign({}, props))] })) }), _jsx(ConfirmDialog, { open: confirmDeleteOpen, onClose: function () { return setConfirmDeleteOpen(false); }, message: t('confirm', { name: monthTitleText }), confirmButtonText: "continue", onConfirm: handleDelete })] }));
};
