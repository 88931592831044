var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import _ from 'lodash';
import { useMemo } from 'react';
import * as yup from 'yup';
import { MetricTarget } from '../../enum';
import { WizardStep } from './constants';
import { useQueueAPI, useQueueStatus } from './queue/selectors';
import { WorkType } from './queue/types';
import { useWizardParams } from './selectors';
import { useWAVMonths } from './workArrivalPattern/selectors';
var generateValidateField = function () {
    var fields = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        fields[_i] = arguments[_i];
    }
    var shaped = { id: yup.number().required() };
    for (var _a = 0, fields_1 = fields; _a < fields_1.length; _a++) {
        var field = fields_1[_a];
        shaped[field] = yup.number().nullable().required();
    }
    return yup.array().of(yup.object().shape(shaped)).required();
};
var useWorkVolumeShape = function () {
    var _a, _b;
    var queueData = useQueueAPI()[0];
    var isDeferrable = !!((_a = queueData.data) === null || _a === void 0 ? void 0 : _a.isDeferrable);
    var isChatCurrency = ((_b = queueData.data) === null || _b === void 0 ? void 0 : _b.worktypeId) === WorkType.Chat;
    return {
        id: yup.number().required(),
        volumes: generateValidateField('volume'),
        answered: yup
            .number()
            .nullable()
            .when('metricTarget', {
            is: function (metricTarget) { return !isDeferrable && metricTarget === MetricTarget.ServiceLevel; },
            then: function (schema) { return schema.required(); },
        }),
        threshold: yup
            .number()
            .nullable()
            .when('metricTarget', {
            is: function (metricTarget) { return !isDeferrable && metricTarget === MetricTarget.ServiceLevel; },
            then: function (schema) { return schema.required(); },
        }),
        targetAsa: yup
            .number()
            .nullable()
            .when('metricTarget', {
            is: function (metricTarget) { return !isDeferrable && metricTarget === MetricTarget.ASA; },
            then: function (schema) { return schema.required(); },
        }),
        targetOccupancy: yup
            .number()
            .nullable()
            .when('metricTarget', {
            is: function (metricTarget) { return !isDeferrable && metricTarget === MetricTarget.Occupancy; },
            then: function (schema) { return schema.required(); },
        }),
        chatConcurrency: isChatCurrency ? yup.number().required() : yup.number().nullable(),
        factor: isDeferrable ? yup.number().required() : yup.number().nullable(),
        maxOccupancy: yup.number().nullable(),
    };
};
var shrinkageShape = {
    id: yup.number().required(),
    shrinkages: yup
        .array()
        .of(generateValidateField('percent'))
        .test('sum-of-percent', 'overError', function (shrinkages) { return shrinkages === null || shrinkages === void 0 ? void 0 : shrinkages.every(function (arr) { return _.sumBy(arr, 'percent') < 100; }); })
        .required(),
};
var useWorkingDayShape = function () {
    var _a;
    var queueData = useQueueAPI()[0];
    var status = useQueueStatus();
    var isDisableDailyOperating = !!((_a = queueData.data) === null || _a === void 0 ? void 0 : _a.isDeferrable) || !!(status === null || status === void 0 ? void 0 : status.wat);
    return {
        id: yup.number().required(),
        volumes: generateValidateField('workingDays'),
        fteDay: yup.number().required().min(0.01),
        timeInterval: yup.number().required(),
        dailyOperatingHours: yup.lazy(function () {
            return isDisableDailyOperating ? yup.number().nullable() : yup.number().required();
        }),
        totalPartTime: yup.number().required(),
        hoursPerDay: yup.number().required(),
        daysPerWeek: yup.number().required(),
    };
};
var staffShape = {
    id: yup.number().required(),
    roles: yup
        .array()
        .of(yup.object().shape({
        id: yup.number().required(),
        ratio: yup.number().nullable(),
        salary: yup.number().nullable(),
        staffCalculate: yup.string().nullable(),
    }))
        .required(),
};
var overtimeShape = __assign(__assign({}, staffShape), { volumes: generateValidateField('rate'), multiplier: yup.number().required(), trainWeeks: yup.number().required() });
var useWAPVolumeShape = function () {
    var months = useWAVMonths();
    var itemShape = {};
    for (var _i = 0, months_1 = months; _i < months_1.length; _i++) {
        var month = months_1[_i];
        itemShape["month".concat(month)] = yup
            .array()
            .of(yup
            .array()
            .of(yup.object().shape({
            workVolume: yup.number().min(0).required(),
        }))
            .required())
            .required()
            .test('sum-of-percent', 'percentage', function (volumes) {
            return volumes.flat().some(function (_a) {
                var workVolume = _a.workVolume;
                return workVolume === null;
            }) ||
                volumes.every(function (arr) { return _.chain(arr).sumBy('workVolume').round(4).value() === 100; });
        });
    }
    return {
        id: yup.number().required(),
        wapVolumes: yup.object().shape(itemShape),
    };
};
export var useValidateForm = function () {
    var workingDayShape = useWorkingDayShape();
    var wapVolumeShape = useWAPVolumeShape();
    var workVolumeShape = useWorkVolumeShape();
    var step = useWizardParams().step;
    var schemaByStep = useMemo(function () {
        var _a;
        var _b;
        var shapeMap = (_a = {},
            _a[WizardStep.WorkVolume] = yup.object().shape(__assign(__assign({}, workVolumeShape), wapVolumeShape)),
            _a[WizardStep.HandleTime] = yup.object().shape({ volumes: generateValidateField('handleTime', 'learnCurve') }),
            _a[WizardStep.Shrinkage] = yup.object().shape(shrinkageShape),
            _a[WizardStep.Attrition] = yup.object().shape({ volumes: generateValidateField('attrition') }),
            _a[WizardStep.WorkingDays] = yup.object().shape(workingDayShape),
            _a[WizardStep.SupportStaff] = yup.object().shape(staffShape),
            _a[WizardStep.Financial] = yup.object().shape(overtimeShape),
            _a[WizardStep.Review] = yup.object().shape(__assign(__assign(__assign(__assign(__assign(__assign({}, workVolumeShape), shrinkageShape), workingDayShape), overtimeShape), wapVolumeShape), { volumes: generateValidateField('volume', 'handleTime', 'learnCurve', 'workingDays', 'attrition', 'rate') })),
            _a);
        return (_b = shapeMap[step]) !== null && _b !== void 0 ? _b : yup.object().shape({});
    }, [workVolumeShape, wapVolumeShape, workingDayShape, step]);
    return schemaByStep;
};
