var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { linkClasses, styled } from '@mui/material';
import { DataGrid as MuiDataGrid, gridClasses } from '@mui/x-data-grid';
import { CustomPagination, NoRowsOverlay, SortIcon, SortedAscendingIcon, SortedDescendingIcon } from './slots';
export var PAGE_SIZE = 20;
var StyledDataGrid = styled(MuiDataGrid)(function (_a) {
    var _b, _c, _d, _e, _f;
    var theme = _a.theme;
    return (_b = {
            width: '100%',
            borderColor: 'transparent'
        },
        _b[".".concat(linkClasses.root)] = {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        _b[".".concat(gridClasses.main)] = {
            backgroundColor: theme.palette.common.white,
        },
        _b[".".concat(gridClasses.overlayWrapper)] = {
            height: theme.typography.pxToRem(200),
        },
        _b[".".concat(gridClasses.footerContainer)] = {
            border: 0,
        },
        _b[".".concat(gridClasses.columnHeader)] = (_c = {
                backgroundColor: theme.palette.text.primary,
                color: theme.palette.common.white,
                userSelect: 'none',
                ':focus-within': {
                    outline: 'none ',
                },
                ':last-of-type': {
                    borderRight: 0,
                }
            },
            _c[".".concat(gridClasses.columnHeaderTitleContainer)] = (_d = {
                    gap: theme.spacing(3)
                },
                _d[".".concat(gridClasses.columnHeaderTitle)] = {
                    fontWeight: theme.typography.fontWeightBold,
                    fontSize: theme.typography.body1.fontSize,
                },
                _d[".".concat(gridClasses.iconButtonContainer)] = {
                    visibility: 'visible',
                    width: 'auto',
                },
                _d),
            _c),
        _b[".".concat(gridClasses.cell)] = (_e = {
                fontSize: theme.typography.body1.fontSize,
                ':focus-within': {
                    outline: 'none ',
                }
            },
            _e["&.".concat(gridClasses.withBorderColor, ":first-of-type")] = {
                borderLeft: "1px solid ".concat(theme.palette.divider),
            },
            _e),
        _b[".".concat(gridClasses.withBorderColor)] = {
            borderColor: theme.palette.divider,
        },
        _b[".".concat(gridClasses.virtualScroller)] = {
            overflowX: 'hidden',
        },
        _b["&.".concat(gridClasses.autoHeight, ", .").concat(gridClasses['virtualScrollerContent--overflowed'])] = (_f = {},
            _f[".".concat(gridClasses['row--lastVisible'], " > .").concat(gridClasses.withBorderColor)] = {
                borderBottomColor: theme.palette.divider,
            },
            _f),
        _b);
});
export var DataGrid = function (_a) {
    var pageSize = _a.pageSize, props = __rest(_a, ["pageSize"]);
    return (_jsx(StyledDataGrid, __assign({ disableColumnMenu: true, disableRowSelectionOnClick: true, showCellVerticalBorder: true, showColumnVerticalBorder: true, slots: {
            columnUnsortedIcon: SortIcon,
            columnSortedAscendingIcon: SortedAscendingIcon,
            columnSortedDescendingIcon: SortedDescendingIcon,
            pagination: CustomPagination,
            noRowsOverlay: NoRowsOverlay,
        }, slotProps: { baseIconButton: { color: 'inherit' } }, initialState: { pagination: { paginationModel: { pageSize: pageSize !== null && pageSize !== void 0 ? pageSize : PAGE_SIZE } } }, columnHeaderHeight: 40, pageSizeOptions: [pageSize !== null && pageSize !== void 0 ? pageSize : PAGE_SIZE] }, props)));
};
