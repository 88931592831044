import { useParams, useSearchParams } from 'react-router-dom';
import { useAPI } from '../../state';
import { toInt } from '../../util';
import { usePdfAPI } from '../pdf/selectors';
import { selectPlanType } from '../wizard/selectors';
import { ActionType, FILE_EXT, FILE_NAME_BY_REPORT, FILE_NAME_BY_TAB } from './constants';
import { useReportAction, useReportDownload, useReportType, useSelectedTab } from './hooks';
import { ViewType } from './resourcing';
var PLAN_API = '/api/plan/report/{basePlanId}';
var SCENARIO_API = '/api/plan/scenario/{id}';
var ADD_SCENARIO = '/api/plan/scenario/{basePlanId}';
var SWITCH_SCENARIO = '/api/plan/switchScenario/{id}';
var IS_VIEW_ONLY_PLAN = '/api/plan/isViewOnly/{id}';
var EXPORT_API = '/api/report/{reportType}/{tab}/{action}?basePlanId={basePlanId}';
var PDF_URL = '/pdf/{type}/{tab}/{basePlanId}';
export var useReportAPI = function () {
    var basePlanId = useParams().basePlanId;
    return useAPI(PLAN_API, { basePlanId: basePlanId });
};
export var useIsViewOnlyPlanAPI = function () {
    var basePlanId = useParams().basePlanId;
    return useAPI(IS_VIEW_ONLY_PLAN, { id: basePlanId });
};
export var useIsViewOnlyPlan = function () {
    var data = useIsViewOnlyPlanAPI()[0].data;
    return data === null || data === void 0 ? void 0 : data.isView;
};
export var useReportPlanType = function () {
    var data = useReportAPI()[0].data;
    return selectPlanType(data);
};
export var useDeleteScenario = function (id) {
    return useAPI(SCENARIO_API, { id: id }, 'DELETE');
};
export var useAddScenarioAPI = function () {
    var basePlanId = useParams().basePlanId;
    return useAPI(ADD_SCENARIO, { basePlanId: basePlanId }, 'POST');
};
export var useRenameScenario = function (id) {
    return useAPI(SCENARIO_API, { id: id }, 'PUT');
};
export var useSwitchScenario = function (id) {
    return useAPI(SWITCH_SCENARIO, { id: id }, 'PUT');
};
export var useViewTypeParams = function () {
    var _a;
    var _b = useSearchParams(), searchParams = _b[0], setSearchParams = _b[1];
    var viewType = (_a = toInt(searchParams.get('viewType'))) !== null && _a !== void 0 ? _a : ViewType.Table;
    var setViewType = function (type) {
        searchParams.set('viewType', "".concat(type));
        setSearchParams(searchParams, { replace: true });
    };
    return [viewType, setViewType];
};
var useFileName = function (action) {
    var _a, _b;
    var report = useReportType();
    var planType = useReportPlanType();
    var tab = useSelectedTab();
    var reportName = (_b = (_a = FILE_NAME_BY_REPORT[report]) === null || _a === void 0 ? void 0 : _a[planType]) !== null && _b !== void 0 ? _b : planType;
    var tabName = FILE_NAME_BY_TAB[tab];
    return "Output_".concat(reportName, "_").concat(tabName, ".").concat(FILE_EXT[action]);
};
export var useExportAPI = function (action, disabled) {
    if (action === void 0) { action = ActionType.Export; }
    var basePlanId = useParams().basePlanId;
    var tab = useSelectedTab();
    var reportType = useReportType();
    var fileName = useFileName(action);
    return useReportDownload(action, EXPORT_API, "Forecast_".concat(fileName), {
        disabled: disabled,
        substitutions: { basePlanId: basePlanId, reportType: reportType, tab: tab, action: action },
    });
};
export var usePrintTableAPI = function () {
    return useExportAPI(ActionType.Print);
};
export var usePrintChartAPI = function (type, disabled) {
    var basePlanId = useParams().basePlanId;
    var tab = useSelectedTab();
    var fileName = useFileName(ActionType.Print);
    var _a = usePdfAPI(PDF_URL, "Graphical_".concat(fileName), { type: type, tab: tab, basePlanId: basePlanId }), data = _a[0], fetchData = _a[1], clearData = _a[2];
    useReportAction(ActionType.Print, fetchData, data.requesting, disabled);
    return [data, fetchData, clearData];
};
