import { useAPI } from '../../state';
import { useAsyncDownload } from '../hooks';
var USERS_API = '/api/user';
var USER_API = '/api/user/{id}/{orgType}';
var ADD_ORG_API = '/api/org';
var VERSION_API = 'api/version';
var RESET_PASSWORD_API = '/api/user/resetPassword/{id}';
var CHANGE_STATUS_API = '/api/user/changeStatus';
var CREATE_EXPORT_LOG_API = '/api/report/audit/export';
export var useUsersAPI = function () {
    return useAPI(USERS_API);
};
export var useUserAPI = function (id, orgType) {
    return useAPI(USER_API, { id: id, orgType: orgType });
};
export var useCompaniesAPI = function () {
    return useAPI(ADD_ORG_API);
};
export var useAddCompanyAPI = function () {
    return useAPI(ADD_ORG_API, {}, 'POST');
};
export var useVersionsAPI = function () {
    return useAPI(VERSION_API);
};
export var useCreateUserAPI = function () {
    return useAPI(USERS_API, {}, 'POST');
};
export var useUpdateUserAPI = function () {
    return useAPI(USERS_API, {}, 'PATCH');
};
export var useResetPasswordAPI = function (id) {
    return useAPI(RESET_PASSWORD_API, { id: id }, 'PUT');
};
export var useChangeStatusAPI = function () {
    return useAPI(CHANGE_STATUS_API, {}, 'PUT');
};
export var useCreateExportLogAPI = function () {
    return useAsyncDownload(CREATE_EXPORT_LOG_API);
};
