var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { faFileChartColumn } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorMessageBox } from '../common';
import { DownloadTemplateDialog } from '../downloadTemplate';
import { PageBody, SubTitle } from '../layout/styled';
import { CreatePlan } from './createPlan';
import { PlanTable } from './planTable';
import { useCreatePlanAPI, useUploadDataAPI, useValidatePlanAPI } from './selectors';
export var Home = function (props) {
    var t = useTranslation('myPlans').t;
    var createPlanData = useCreatePlanAPI()[0];
    var validatePlanData = useValidatePlanAPI()[0];
    var uploadData = useUploadDataAPI()[0];
    var _a = useState(false), openDownloadTemplateDialog = _a[0], setOpenDownloadTemplateDialog = _a[1];
    return (_jsxs(PageBody, { children: [_jsxs(Typography, __assign({ variant: "h1" }, { children: [_jsx(FontAwesomeIcon, { icon: faFileChartColumn }), "\u00A0\u00A0", t('title')] })), _jsxs(SubTitle, { children: [t('subTitle'), !props.handleImport && (_jsxs(_Fragment, { children: [_jsx("span", { children: t('downloadSubTitle') }), _jsx(Link, __assign({ sx: { verticalAlign: 'top' }, component: "button", onClick: function () { return setOpenDownloadTemplateDialog(true); } }, { children: t('downloadThisTemplate') }))] }))] }), _jsx(ErrorMessageBox, { errors: [validatePlanData.error, createPlanData.error, uploadData.error] }), _jsx(CreatePlan, { handleImport: props.handleImport, defaultValues: props.defaultValues }), _jsx(PlanTable, {}), props.children, _jsx(DownloadTemplateDialog, { open: openDownloadTemplateDialog, onClose: function () { return setOpenDownloadTemplateDialog(false); } })] }));
};
