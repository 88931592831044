var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import { createContext, useCallback, useEffect, useMemo, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useCompletedStep, useQueueAPI, useQueuesAPI, useRefreshQueueData, useSaveQueueAPI } from './queue/selectors';
import { usePlanAPI, useWizardParams } from './selectors';
import { useDefaultValues } from './useDefaultValue';
import { useValidateForm } from './validateForm';
export var WizardContext = createContext({});
export var WizardProvider = function (props) {
    var _a;
    var navigate = useNavigate();
    var defaultValues = useDefaultValues();
    var validateForm = useValidateForm();
    var actionRef = useRef();
    var callbackRef = useRef();
    var methods = useForm({
        defaultValues: defaultValues,
        resolver: yupResolver(validateForm),
    });
    var reset = methods.reset, getValues = methods.getValues, handleSubmit = methods.handleSubmit, formState = methods.formState;
    var _b = formState !== null && formState !== void 0 ? formState : {}, isDirty = _b.isDirty, isValid = _b.isValid;
    var _c = useWizardParams(), step = _c.step, queueId = _c.queueId;
    var queueData = useQueueAPI()[0];
    var queuesData = useQueuesAPI()[0];
    var _d = usePlanAPI(), planData = _d[0], fetchPlan = _d[1];
    var completedStep = useCompletedStep()[0];
    var _e = useSaveQueueAPI(), savedData = _e[0], fetchSave = _e[1], clearSavedData = _e[2];
    var requesting = !!savedData.requesting || !!queueData.requesting || !!queuesData.requesting;
    var refreshData = useRefreshQueueData();
    useEffect(function () {
        reset(defaultValues);
    }, [defaultValues, reset]);
    useEffect(function () {
        var _a;
        if (savedData.data) {
            refreshData();
            var currency = getValues().currency;
            if (currency !== ((_a = planData.data) === null || _a === void 0 ? void 0 : _a.currency)) {
                fetchPlan();
            }
        }
        return clearSavedData;
    }, [clearSavedData, fetchPlan, getValues, (_a = planData.data) === null || _a === void 0 ? void 0 : _a.currency, refreshData, savedData.data]);
    useEffect(function () {
        actionRef.current = undefined;
        callbackRef.current = undefined;
    }, [savedData.error]);
    useEffect(function () {
        if (queuesData.data && callbackRef.current) {
            callbackRef.current(completedStep);
            callbackRef.current = undefined;
            actionRef.current = undefined;
        }
    }, [completedStep, queuesData.data]);
    var handleSave = useCallback(function (action, callback) {
        if (action === void 0) { action = 'submit'; }
        return function (e) {
            var shouldSkipSubmit = !step || (!isDirty && action !== 'next') || (!isDirty && isValid);
            if (shouldSkipSubmit) {
                callback === null || callback === void 0 ? void 0 : callback();
                if (action !== 'submit') {
                    return;
                }
            }
            handleSubmit === null || handleSubmit === void 0 ? void 0 : handleSubmit(function (_a) {
                var shrinkages = _a.shrinkages, _b = _a.wapVolumes, wapVolumes = _b === void 0 ? {} : _b, values = __rest(_a, ["shrinkages", "wapVolumes"]);
                if (!isDirty || requesting)
                    return;
                callbackRef.current = callback;
                actionRef.current = action;
                fetchSave(__assign(__assign({}, values), { shrinkages: _.flatten(shrinkages), wapVolumes: _.flattenDeep(Object.values(wapVolumes)) }));
            })(e);
        };
    }, [fetchSave, handleSubmit, isDirty, isValid, requesting, step]);
    var goToStep = useCallback(function (nextStep) {
        navigate("".concat(window.location.pathname, "?queueId=").concat(queueId, "&step=").concat(nextStep));
        window.scrollTo(0, 0);
    }, [navigate, queueId]);
    var goToQueue = useCallback(function (id) {
        navigate("".concat(window.location.pathname, "?queueId=").concat(id, "&step=").concat(step));
    }, [navigate, step]);
    var value = useMemo(function () { return ({ handleSave: handleSave, actionRef: actionRef, requesting: requesting, goToStep: goToStep, goToQueue: goToQueue, methods: methods }); }, [goToQueue, goToStep, handleSave, methods, requesting]);
    return (_jsx(WizardContext.Provider, __assign({ value: value }, { children: _jsx(FormProvider, __assign({}, methods, { children: props.children })) })));
};
