var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { FormControlLabel, InputAdornment, Radio, Stack, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MetricTarget } from '../../../enum';
import { FormField, NumberInput, RadioGroup } from '../../common';
import { useIsViewOnlyPlan } from '../../report/selectors';
import { BasePanel } from '../common/basePanel';
import { BaseVolumeTable } from '../common/baseTable';
import { QueuePanel } from '../common/queuePanel';
import { StepContainer, StepPanel } from '../common/stepContainer';
import { useQueueAPI } from '../queue/selectors';
import { WorkType } from '../queue/types';
import { usePlanType } from '../selectors';
import { WAPAccordion } from '../workArrivalPattern';
var DetailForm = function (_a) {
    var watch = _a.watch, clearErrors = _a.clearErrors, control = _a.control, isChatCurrency = _a.isChatCurrency;
    var t = useTranslation('wizard', { keyPrefix: 'workVolume' }).t;
    var isViewOnly = useIsViewOnlyPlan();
    var metricTarget = watch('metricTarget');
    useEffect(function () {
        clearErrors === null || clearErrors === void 0 ? void 0 : clearErrors(['answered', 'threshold', 'targetAsa', 'targetOccupancy', 'maxOccupancy']);
    }, [metricTarget, clearErrors]);
    return (_jsxs(Stack, __assign({ direction: "row", gap: 8, flexWrap: "wrap" }, { children: [metricTarget === MetricTarget.ASA && (_jsx(FormField, __assign({ required: true, name: "targetAsa", label: t('asaSec'), control: control }, { children: _jsx(NumberInput, { disabled: isViewOnly, min: 1, max: 99999 }) }))), (metricTarget === MetricTarget.ServiceLevel || metricTarget === MetricTarget.ASA) && (_jsxs(_Fragment, { children: [_jsx(FormField, __assign({ required: metricTarget === MetricTarget.ServiceLevel, name: "answered", label: t('answered'), control: control, extraLabel: metricTarget === MetricTarget.ASA ? t('optional') : '' }, { children: _jsx(NumberInput, { disabled: isViewOnly, max: 100, InputProps: {
                                endAdornment: _jsx(InputAdornment, __assign({ position: "end" }, { children: "%" })),
                            } }) })), _jsx(FormField, __assign({ required: metricTarget === MetricTarget.ServiceLevel, name: "threshold", label: t('threshold'), control: control, extraLabel: metricTarget === MetricTarget.ASA ? t('optional') : '' }, { children: _jsx(NumberInput, { disabled: isViewOnly, max: 1000000 }) }))] })), metricTarget === MetricTarget.Occupancy && (_jsx(FormField, __assign({ required: true, name: "targetOccupancy", label: t('occupancy'), control: control }, { children: _jsx(NumberInput, { disabled: isViewOnly, min: -1, max: 99.9999, decimal: 4, InputProps: {
                        endAdornment: _jsx(InputAdornment, __assign({ position: "end" }, { children: "%" })),
                    } }) }))), isChatCurrency && (_jsx(FormField, __assign({ required: true, name: "chatConcurrency", label: t('chatConcurrency'), control: control }, { children: _jsx(NumberInput, { disabled: isViewOnly, decimal: 1, min: 1.0, max: 99.9 }) }))), (metricTarget === MetricTarget.ServiceLevel || metricTarget === MetricTarget.ASA) && (_jsx(FormField, __assign({ name: "maxOccupancy", label: t('maxOccupancy'), extraLabel: t('optional'), control: control }, { children: _jsx(NumberInput, { disabled: isViewOnly, min: -1, max: 99.9999, decimal: 4, InputProps: {
                        endAdornment: _jsx(InputAdornment, __assign({ position: "end" }, { children: "%" })),
                    } }) })))] })));
};
var MetricTargetForm = function (props) {
    var t = useTranslation('wizard', { keyPrefix: 'workVolume' }).t;
    var metricTarget = props.watch('metricTarget');
    return (_jsxs(_Fragment, { children: [_jsx(FormField, __assign({ name: "metricTarget", control: props.control }, { children: _jsxs(RadioGroup, __assign({ row: true }, { children: [_jsx(FormControlLabel, { value: MetricTarget.ServiceLevel, control: _jsx(Radio, {}), label: t("serviceLevel") }), _jsx(FormControlLabel, { value: MetricTarget.ASA, control: _jsx(Radio, {}), label: t("asa") }), _jsx(FormControlLabel, { value: MetricTarget.Occupancy, control: _jsx(Radio, {}), label: t("occupancy") })] })) })), metricTarget && (_jsx(DetailForm, { setValue: props.setValue, isChatCurrency: props.isChatCurrency, control: props.control, watch: props.watch, clearErrors: props.clearErrors }))] }));
};
export var WorkVolumeContent = function () {
    var _a, _b;
    var t = useTranslation('wizard', { keyPrefix: 'workVolume' }).t;
    var isViewOnly = useIsViewOnlyPlan();
    var queueData = useQueueAPI()[0];
    var isChatCurrency = ((_a = queueData.data) === null || _a === void 0 ? void 0 : _a.worktypeId) === WorkType.Chat;
    var _c = useFormContext(), control = _c.control, setValue = _c.setValue, watch = _c.watch, clearErrors = _c.clearErrors;
    return (_jsxs(_Fragment, { children: [_jsx(BaseVolumeTable, { name: "volume", averagePrecision: 0, min: 0 }), _jsxs(Typography, __assign({ variant: "subtitle1" }, { children: [t('targetService'), " "] })), queueData.data && !queueData.data.isDeferrable && (_jsx(MetricTargetForm, { setValue: setValue, control: control, isChatCurrency: isChatCurrency, watch: watch, clearErrors: clearErrors })), ((_b = queueData.data) === null || _b === void 0 ? void 0 : _b.isDeferrable) && (_jsx(Stack, __assign({ direction: "row", spacing: 4 }, { children: _jsx(FormField, __assign({ required: true, name: "factor", label: t('productivityFactor'), control: control }, { children: _jsx(NumberInput, { disabled: isViewOnly, min: 1, max: 100, InputProps: {
                            endAdornment: _jsx(InputAdornment, __assign({ position: "end" }, { children: "%" })),
                        } }) })) })))] }));
};
export var WorkVolumeStep = function () {
    var t = useTranslation('wizard', { keyPrefix: 'workVolume' }).t;
    var planType = usePlanType();
    return (_jsx(StepContainer, __assign({ leftContent: _jsx(QueuePanel, {}) }, { children: _jsxs(StepPanel, { children: [_jsx(BasePanel, __assign({ title: t('name'), description: t('message', { planType: planType }) }, { children: _jsx(WorkVolumeContent, {}) })), _jsx(WAPAccordion, {})] }) })));
};
