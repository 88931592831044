var _a;
export var Role;
(function (Role) {
    Role[Role["Superadmin"] = 0] = "Superadmin";
    Role[Role["Premium"] = 1] = "Premium";
    Role[Role["Standard"] = 2] = "Standard";
    Role[Role["Lite"] = 3] = "Lite";
})(Role || (Role = {}));
export var UserStatus;
(function (UserStatus) {
    UserStatus["Active"] = "A";
    UserStatus["Inactive"] = "I";
})(UserStatus || (UserStatus = {}));
export var PlanType;
(function (PlanType) {
    PlanType["Monthly"] = "Monthly";
    PlanType["Weekly"] = "Weekly";
})(PlanType || (PlanType = {}));
export var Worktime;
(function (Worktime) {
    Worktime[Worktime["Deferrable"] = 1] = "Deferrable";
    Worktime[Worktime["NonDeferrable"] = 2] = "NonDeferrable";
})(Worktime || (Worktime = {}));
export var DifferenceMode;
(function (DifferenceMode) {
    DifferenceMode["Absolute"] = "Absolute";
    DifferenceMode["Percentage"] = "Percentage";
})(DifferenceMode || (DifferenceMode = {}));
export var MFAType;
(function (MFAType) {
    MFAType["TOTP"] = "SOFTWARE_TOKEN_MFA";
    MFAType["SMS"] = "SMS_MFA";
})(MFAType || (MFAType = {}));
export var MFAValidationType;
(function (MFAValidationType) {
    MFAValidationType[MFAValidationType["SecretKey"] = 0] = "SecretKey";
    MFAValidationType[MFAValidationType["QRCode"] = 1] = "QRCode";
})(MFAValidationType || (MFAValidationType = {}));
export var PlanColor = {
    0: { HColor: '#333366', CColor: '#D7E3F1' },
    1: { HColor: '#b25c03', CColor: '#f2d5b7' },
    2: { HColor: '#136a21', CColor: '#bce1c2' },
    3: { HColor: '#994f4f', CColor: '#e8cfd2' },
    4: { HColor: '#666633', CColor: '#e1e0cb' },
    5: { HColor: '#5c0276', CColor: '#dbd0f5' },
};
export var Currency;
(function (Currency) {
    Currency["Dollar"] = "$";
    Currency["Pound"] = "\u00A3";
    Currency["Euro"] = "\u20AC";
    Currency["Rupee"] = "\u20B9";
})(Currency || (Currency = {}));
export var CURRENCIES = [
    { name: 'Dollar ($)', id: Currency.Dollar },
    { name: 'Pound (£)', id: Currency.Pound },
    { name: 'Euro (€)', id: Currency.Euro },
    { name: 'Rupee (₹)', id: Currency.Rupee },
];
export var CURRENCY_CODE = (_a = {},
    _a[Currency.Dollar] = 'USD',
    _a[Currency.Pound] = 'GBP',
    _a[Currency.Euro] = 'EUR',
    _a[Currency.Rupee] = 'INR',
    _a);
export var CognitoUserAttribute;
(function (CognitoUserAttribute) {
    CognitoUserAttribute["Email"] = "email";
    CognitoUserAttribute["EmailVerified"] = "email_verified";
    CognitoUserAttribute["PhoneNumber"] = "phone_number";
    CognitoUserAttribute["PhoneNumberVerified"] = "phone_number_verified";
})(CognitoUserAttribute || (CognitoUserAttribute = {}));
export var OrgType;
(function (OrgType) {
    OrgType["Cinareo"] = "Cinareo";
    OrgType["Genesys"] = "Genesys";
})(OrgType || (OrgType = {}));
export var MetricTarget;
(function (MetricTarget) {
    MetricTarget["ServiceLevel"] = "SERVICE_LEVEL";
    MetricTarget["ASA"] = "ASA";
    MetricTarget["Occupancy"] = "OCCUPANCY";
})(MetricTarget || (MetricTarget = {}));
