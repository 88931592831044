var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faUsers } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Stack, Typography, styled } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OrgType } from '../../enum';
import { Autocomplete, InputLabel } from '../common';
import { useDialog } from '../hooks';
import { PageBody, SubTitle } from '../layout/styled';
import { AddCompanyDialog } from './addCompany';
import { ChangeStatusDialog } from './changeStatus';
import { ExportLogDialog } from './exportLog';
import { ResetPasswordDialog } from './resetPassword';
import { DialogMode } from './types';
import { UserDialog } from './userDialog';
import { UserTable } from './userTable';
var GroupButton = styled(Box)(function (_a) {
    var theme = _a.theme;
    return ({
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: theme.spacing(4),
    });
});
var orgTypes = [OrgType.Cinareo, OrgType.Genesys];
export var ManageUsers = function () {
    var t = useTranslation('manageUser').t;
    var _a = useDialog(), dialogMode = _a.dialogMode, selectedUser = _a.data, handleOpenDialog = _a.handleOpenDialog, handleCloseDialog = _a.handleCloseDialog;
    var _b = useState(OrgType.Cinareo), selectedOrgType = _b[0], setSelectedOrgType = _b[1];
    var handleUserCreated = function () {
        setSelectedOrgType(OrgType.Cinareo);
    };
    return (_jsxs(PageBody, { children: [_jsxs(Typography, __assign({ variant: "h1", "data-testid": "main-title" }, { children: [_jsx(FontAwesomeIcon, { icon: faUsers }), "\u00A0\u00A0", t('mainTitle')] })), _jsx(SubTitle, { children: t('subtitle') }), _jsxs(GroupButton, { children: [_jsxs(Button, __assign({ variant: "contained", onClick: handleOpenDialog.bind(null, DialogMode.User, undefined), "data-testid": "add-user" }, { children: ["+\u00A0\u00A0", t('addNewUser')] })), _jsxs(Button, __assign({ variant: "contained", onClick: handleOpenDialog.bind(null, DialogMode.Company, undefined), "data-testid": "add-company" }, { children: ["+\u00A0\u00A0", t('addNewCompany')] })), _jsxs(Stack, __assign({ direction: "row", spacing: 2, alignItems: "center", ml: "auto" }, { children: [_jsx(InputLabel, { children: t('platform') }), _jsx(Autocomplete, { sx: { width: '15rem' }, options: orgTypes, value: selectedOrgType, onChange: setSelectedOrgType })] })), _jsx(Button, __assign({ variant: "outlined", sx: { textTransform: 'uppercase' }, onClick: handleOpenDialog.bind(null, DialogMode.ExportLog, undefined) }, { children: t('exportLog.title') }))] }), _jsx(UserTable, { handleOpenDialog: handleOpenDialog, platform: selectedOrgType }), _jsx(UserDialog, { open: dialogMode === DialogMode.User, user: selectedUser, onClose: handleCloseDialog, onUserCreated: handleUserCreated }), _jsx(AddCompanyDialog, { open: dialogMode === DialogMode.Company, onClose: handleCloseDialog }), _jsx(ResetPasswordDialog, { open: dialogMode === DialogMode.ResetPassword, user: selectedUser, onClose: handleCloseDialog }), _jsx(ChangeStatusDialog, { open: dialogMode === DialogMode.ChangeStatus, user: selectedUser, onClose: handleCloseDialog }), _jsx(ExportLogDialog, { open: dialogMode === DialogMode.ExportLog, onClose: handleCloseDialog })] }));
};
