var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormControl, styled } from '@mui/material';
import { useRef } from 'react';
import { NumericFormat } from 'react-number-format';
import { getNumberValue, stringToNumber, useNumberInput } from '../numberInput/hooks';
var StyledFormControl = styled(FormControl)(function (_a) {
    var theme = _a.theme, invalid = _a["aria-invalid"], disabled = _a.disabled;
    return ({
        boxSizing: 'border-box',
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: invalid ? theme.palette.error.main : 'transparent',
        borderRadius: disabled ? 0 : theme.shape.borderRadius,
        minHeight: theme.typography.pxToRem(40),
        padding: theme.spacing(0, 1),
        width: '100%',
        backgroundColor: disabled ? theme.palette.grey[400] : theme.palette.common.white,
        ':hover:not(:disabled)': {
            borderColor: invalid ? theme.palette.error.main : theme.palette.primary.main,
        },
        ':focus-within': {
            outline: 0,
            boxShadow: "inset 0 0 0 1px ".concat(invalid ? theme.palette.error.main : theme.palette.primary.main),
        },
    });
});
export var StyledInputSuffix = styled('div')(function (_a) {
    var theme = _a.theme;
    return ({
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: theme.spacing(3),
        display: 'flex',
        alignItems: 'center',
        userSelect: 'none',
    });
});
var StyledInput = styled('input')(function (_a) {
    var theme = _a.theme;
    return ({
        boxSizing: 'border-box',
        position: 'relative',
        width: '100%',
        fontSize: theme.typography.body1.fontSize,
        backgroundColor: 'transparent',
        textAlign: 'center',
        border: 'none',
        borderRadius: theme.shape.borderRadius,
        minHeight: theme.typography.pxToRem(30),
        outline: 'none',
        ':focus-within': {
            outline: 0,
        },
        '::-webkit-input-placeholder': {
            color: 'currentColor',
            opacity: 0.42,
        },
    });
});
export var NativeNumberInput = function (_a) {
    var onChange = _a.onChange, min = _a.min, max = _a.max, decimal = _a.decimal, setValue = _a.setValue, inputRef = _a.inputRef, error = _a.error, className = _a.className, suffix = _a.suffix, props = __rest(_a, ["onChange", "min", "max", "decimal", "setValue", "inputRef", "error", "className", "suffix"]);
    var numericProps = useNumberInput(__assign({ onChange: onChange, min: min, max: max, decimal: decimal }, props));
    var ref = useRef(null);
    var handlePaste = function (event) {
        var _a, _b, _c, _d, _e;
        var startRow = (_a = ref.current) === null || _a === void 0 ? void 0 : _a.closest('tr');
        if (!setValue || !startRow)
            return;
        event.preventDefault();
        var rows = (_b = startRow.parentNode) === null || _b === void 0 ? void 0 : _b.childNodes;
        var startRowIdx = Array.prototype.indexOf.call(rows, startRow);
        var selectors = "input[type=\"text\"]:not([disable]),span";
        var inputs = startRow === null || startRow === void 0 ? void 0 : startRow.querySelectorAll(selectors);
        var startCellIdx = Array.prototype.indexOf.call(inputs, ref.current);
        var clipboardData = (_c = event.clipboardData) === null || _c === void 0 ? void 0 : _c.getData('text');
        var data = clipboardData.split(/\r?\n/).map(function (row) { return row.split(/\t/); });
        if (navigator.userAgent.indexOf('Mac OS X') === -1 && !((_d = data.at(-1)) === null || _d === void 0 ? void 0 : _d[0])) {
            data.pop();
        }
        for (var rowIdx = startRowIdx; rowIdx < Math.min(rows.length, data.length + startRowIdx); rowIdx++) {
            var rowInputs = rows[rowIdx].querySelectorAll(selectors);
            var dataRow = data[rowIdx - startRowIdx];
            for (var cellIdx = startCellIdx; cellIdx < Math.min(rowInputs.length, dataRow.length + startCellIdx); cellIdx++) {
                var cell = dataRow[cellIdx - startCellIdx];
                var targetInput = rowInputs[cellIdx];
                var _f = getNumberValue(cell, JSON.parse((_e = targetInput === null || targetInput === void 0 ? void 0 : targetInput.dataset.meta) !== null && _e !== void 0 ? _e : '{}')), floatValue = _f.floatValue, isAllowed = _f.isAllowed;
                var targetValue = stringToNumber(targetInput.value);
                if (targetInput && targetInput.tagName.toLowerCase() === 'input' && isAllowed && floatValue !== targetValue) {
                    setValue(targetInput.name, floatValue, { shouldDirty: true });
                }
            }
        }
    };
    return (_jsxs(StyledFormControl, __assign({ fullWidth: true, className: className, disabled: props.disabled, "aria-invalid": error }, { children: [_jsx(NumericFormat, __assign({ "data-testid": "inputnumber", customInput: StyledInput }, props, numericProps, { onPaste: handlePaste, getInputRef: function (elm) {
                    ref.current = elm;
                    typeof inputRef === 'function' && inputRef(elm);
                }, "data-meta": JSON.stringify({ min: min, max: max, decimal: decimal }) })), _jsx(StyledInputSuffix, { children: suffix })] })));
};
