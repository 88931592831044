import { createSelector } from '@reduxjs/toolkit';
import _ from 'lodash';
import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { PlanType } from '../../enum';
import { useAPI } from '../../state';
import { ActionType } from '../report/constants';
import { useReportDownload } from '../report/hooks';
import { useReportAPI, useReportPlanType } from '../report/selectors';
import { PLAN_COLORS } from '../wizard/constants';
import { createTimelineSelector, selectTimelineOptions } from '../wizard/selectors';
import { useDailyContext } from './context';
var WAP_QUEUES_API = '/api/queue/wap/{basePlanId}';
var DAILY_REPORT_DETAIL_API = '/api/report/daily';
var PRINT_API = '/api/report/daily/print';
var EXPORT_API = '/api/report/daily/export';
var EXPORT_FILE_NAME = 'Forecast_Output_Daily_Plan';
var selectQueues = function (queues) { return queues; };
var selectQueueMap = createSelector(selectQueues, function (queues) { return _.keyBy(queues, 'id'); });
var selectQueuesGroup = createSelector(selectQueues, function (queues) {
    return _.chain(queues)
        .groupBy('planId')
        .mapValues(function (children) { return ({ queues: children, byName: _.keyBy(children, 'name') }); })
        .value();
});
var selectUniqueQueues = createSelector(selectQueuesGroup, selectQueues, function (group, queues) {
    return (queues === null || queues === void 0 ? void 0 : queues.length) ? group[queues[0].planId].queues : [];
});
var createQueueSelector = function (id) {
    if (id === void 0) { id = 0; }
    return createSelector(selectQueueMap, function (map) { return map[id]; });
};
var createNextQueueSelector = function (id, planId) {
    if (id === void 0) { id = 0; }
    if (planId === void 0) { planId = 0; }
    return createSelector(selectQueuesGroup, selectQueueMap, function (group, map) { var _a, _b, _c; return (_c = (_a = group[planId]) === null || _a === void 0 ? void 0 : _a.byName[(_b = map[id]) === null || _b === void 0 ? void 0 : _b.name]) === null || _c === void 0 ? void 0 : _c.id; });
};
var selectScenarios = function (plan) { return plan === null || plan === void 0 ? void 0 : plan.scenarios; };
var selectScenarioMap = createSelector(selectScenarios, function (scenario) { return _.keyBy(scenario, 'id'); });
var createScenarioSelector = function (id) {
    if (id === void 0) { id = 0; }
    return createSelector(selectScenarioMap, function (map) { return map[id]; });
};
export var useWAPQueuesAPI = function () {
    var basePlanId = useParams().basePlanId;
    return useAPI(WAP_QUEUES_API, { basePlanId: basePlanId });
};
export var useUniqueQueues = function () {
    var data = useWAPQueuesAPI()[0].data;
    return selectUniqueQueues(data);
};
export var useQueueById = function (id) {
    var data = useWAPQueuesAPI()[0].data;
    var selectQueue = useMemo(function () { return createQueueSelector(id); }, [id]);
    return selectQueue(data);
};
export var useNextQueueId = function (queueId, planId) {
    var data = useWAPQueuesAPI()[0].data;
    var selectNextQueueId = useMemo(function () { return createNextQueueSelector(queueId, planId); }, [planId, queueId]);
    return selectNextQueueId(data);
};
export var usePlanColor = function () {
    var _a;
    var data = useReportAPI()[0].data;
    var planId = useDailyContext('planId')[0];
    var selectScenario = useMemo(function () { return createScenarioSelector(planId); }, [planId]);
    var plan = selectScenario(data);
    return PLAN_COLORS[(_a = plan === null || plan === void 0 ? void 0 : plan.colorIndex) !== null && _a !== void 0 ? _a : 0];
};
export var useReportTimeline = function (includeYear, includeWeek) {
    if (includeYear === void 0) { includeYear = false; }
    if (includeWeek === void 0) { includeWeek = false; }
    var addColumns = [];
    for (var _i = 2; _i < arguments.length; _i++) {
        addColumns[_i - 2] = arguments[_i];
    }
    var data = useReportAPI()[0].data;
    var selectTimelines = useMemo(function () { return createTimelineSelector(includeYear, includeWeek, addColumns); }, [includeYear, includeWeek, addColumns]);
    return selectTimelines(data);
};
export var useReportTimelineOptions = function () {
    var data = useReportAPI()[0].data;
    return selectTimelineOptions(data);
};
export var useDailyAPI = function () {
    var state = useDailyContext()[0];
    var _a = useAPI(DAILY_REPORT_DETAIL_API), data = _a[0], fetchData = _a[1], clearData = _a[2];
    var fetchDataWrapper = useCallback(function () {
        if (state) {
            return fetchData(undefined, _.pick(state, 'queueId', 'headcount', 'month'));
        }
    }, [fetchData, state]);
    return [data, fetchDataWrapper, clearData];
};
export var useExportAPI = function () {
    var state = useDailyContext()[0];
    var params = useMemo(function () { return _.pick(state, 'planId', 'headcount'); }, [state]);
    return useReportDownload(ActionType.Export, EXPORT_API, "".concat(EXPORT_FILE_NAME, ".xlsx"), { disabled: !state, params: params });
};
export var usePrintAPI = function () {
    var state = useDailyContext()[0];
    var planType = useReportPlanType();
    var params = useMemo(function () { return _.pick(state, 'queueId', 'headcount'); }, [state]);
    return useReportDownload(ActionType.Print, PRINT_API, "".concat(EXPORT_FILE_NAME, ".pdf"), {
        disabled: !state || planType !== PlanType.Monthly,
        params: params,
    });
};
