import { createTheme } from '@mui/material';
var defaultTheme = createTheme({
    palette: {
        primary: {
            main: '#333366',
            light: '#D7E3F1',
        },
        text: {
            primary: '#333333',
            secondary: '#7F7F7F',
        },
        background: {
            default: '#F2F2F2',
        },
        divider: '#D7D7D7',
        grey: {
            '400': '#e4e4e4',
            '500': '#cbcbcb',
            '600': '#AAAAAA',
            '800': '#666666',
        },
        error: {
            main: '#f44336',
            dark: '#8a0d1a',
        },
    },
    spacing: function (value) { return "".concat(value * 0.25, "rem"); },
    typography: function () { return ({
        fontFamily: 'Arial, sans-serif',
        pxToRem: function (px) { return "".concat(px / 16, "rem"); },
    }); },
});
export var theme = createTheme(defaultTheme, {
    typography: {
        h1: {
            fontSize: defaultTheme.typography.pxToRem(24),
            fontWeight: defaultTheme.typography.fontWeightBold,
        },
        h2: {
            fontSize: defaultTheme.typography.pxToRem(22),
            fontWeight: defaultTheme.typography.fontWeightBold,
        },
        h3: {
            fontSize: defaultTheme.typography.pxToRem(20),
            fontWeight: defaultTheme.typography.fontWeightBold,
        },
        subtitle1: {
            fontSize: defaultTheme.typography.pxToRem(18),
            fontWeight: defaultTheme.typography.fontWeightBold,
        },
        subtitle2: {
            fontSize: defaultTheme.typography.pxToRem(16),
            fontWeight: defaultTheme.typography.fontWeightBold,
        },
        body2: {
            fontSize: defaultTheme.typography.pxToRem(14),
        },
        caption: {
            fontSize: defaultTheme.typography.pxToRem(12),
        },
        button: {
            fontSize: defaultTheme.typography.pxToRem(14),
            fontWeight: defaultTheme.typography.fontWeightBold,
            textTransform: 'unset',
        },
    },
    components: {
        MuiLink: {
            styleOverrides: {
                root: {
                    textDecorationColor: defaultTheme.palette.primary.main,
                    ':hover': {
                        color: defaultTheme.palette.text.primary,
                        textDecoration: 'none',
                    },
                    ':disabled': {
                        opacity: 0.38,
                        cursor: 'not-allowed',
                    },
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    fontSize: defaultTheme.typography.body2.fontSize,
                    color: defaultTheme.palette.primary.main,
                    '&:hover': {
                        backgroundColor: defaultTheme.palette.primary.light,
                    },
                    minWidth: defaultTheme.typography.pxToRem(120),
                },
            },
        },
        MuiButton: {
            variants: [
                {
                    props: { size: 'small' },
                    style: {
                        fontSize: defaultTheme.typography.body2.fontSize,
                        fontWeight: defaultTheme.typography.fontWeightRegular,
                    },
                },
                {
                    props: { size: 'medium' },
                    style: {
                        minHeight: defaultTheme.typography.pxToRem(40),
                    },
                },
                {
                    props: { variant: 'outlined' },
                    style: {
                        backgroundColor: defaultTheme.palette.common.white,
                        ':hover': {
                            backgroundColor: defaultTheme.palette.common.white,
                        },
                    },
                },
            ],
            styleOverrides: {
                root: {
                    ':disabled': {
                        color: defaultTheme.palette.grey[600],
                        backgroundColor: defaultTheme.palette.background.default,
                        borderColor: defaultTheme.palette.grey[800],
                    },
                    '&:hover': {
                        opacity: 0.75,
                    },
                },
                contained: {
                    border: "1px solid transparent",
                },
                text: {
                    border: "1px solid transparent",
                },
            },
        },
        MuiInputBase: {
            defaultProps: {
                size: 'small',
            },
            styleOverrides: {
                root: {
                    backgroundColor: defaultTheme.palette.common.white,
                    '&.Mui-disabled': {
                        backgroundColor: defaultTheme.palette.grey[400],
                    },
                },
                multiline: {
                    padding: '0 !important',
                },
                inputMultiline: {
                    padding: "".concat(defaultTheme.spacing(2, 4), " !important"),
                },
            },
        },
        MuiTextField: {
            defaultProps: {
                fullWidth: true,
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    marginLeft: 0,
                },
            },
        },
        MuiDialog: {
            defaultProps: {
                scroll: 'body',
                fullWidth: true,
            },
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    padding: defaultTheme.spacing(4, 6),
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    '&.Mui-selected': {
                        color: defaultTheme.palette.text.primary,
                    },
                },
            },
        },
        MuiAccordion: {
            styleOverrides: {
                root: {
                    '&:before': {
                        display: 'none',
                    },
                },
            },
        },
        MuiSwitch: {
            styleOverrides: {
                thumb: {
                    border: "1px solid ".concat(defaultTheme.palette.grey[500]),
                },
            },
        },
        MuiInputAdornment: {
            styleOverrides: {
                root: {
                    ' .MuiTypography-root': {
                        color: defaultTheme.palette.text.primary,
                    },
                },
            },
        },
        MuiRadio: {
            defaultProps: {
                size: 'small',
            },
        },
    },
});
