var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Typography, stackClasses, styled } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { usePlatformContext } from '../../app/selectors';
import { FormField, NumberInput } from '../../common';
import { useIsViewOnlyPlan } from '../../report/selectors';
import { BasePanel } from '../common/basePanel';
import { BaseVolumeTable } from '../common/baseTable';
import { QueueStatusType } from '../queue/types';
import { usePlanType } from '../selectors';
var StyledBasePanel = styled(Box)(function () {
    var _a;
    return (_a = {},
        _a[".".concat(stackClasses.root, " > .").concat(stackClasses.root, "  > :last-child")] = {
            flexShrink: 0.25,
        },
        _a);
});
export var OvertimeCostContent = function (_a) {
    var disableDescription = _a.disableDescription;
    var t = useTranslation('wizard', { keyPrefix: 'financial' }).t;
    var isViewOnly = useIsViewOnlyPlan();
    var control = useFormContext().control;
    var context = usePlatformContext();
    return (_jsxs(_Fragment, { children: [_jsx(BaseVolumeTable, { addColumns: ['Average'], name: "rate", decimal: 2, max: 99.99, defaultValue: 0, min: 0, suffix: "%" }), _jsxs(Box, { children: [_jsx(Typography, __assign({ variant: "subtitle2", gutterBottom: true }, { children: t('multiplierTitle') })), !disableDescription && (_jsx(Typography, __assign({ variant: "body1", gutterBottom: true, paddingBottom: 2 }, { children: t('multiplierDescription') }))), _jsx(FormField, __assign({ name: "multiplier", control: control, defaultValue: 1 }, { children: _jsx(NumberInput, { disabled: isViewOnly, decimal: 2, max: 5, placeholder: "1" }) }))] }), _jsxs(Box, { children: [_jsx(Typography, __assign({ variant: "subtitle2", gutterBottom: true }, { children: t('trainingLengthTitle') })), !disableDescription && (_jsx(Typography, __assign({ variant: "body1", gutterBottom: true, paddingBottom: 2 }, { children: t('trainingLengthDescription', { context: context }) }))), _jsx(FormField, __assign({ name: "trainWeeks", control: control, unitLabel: "WEEKS", defaultValue: 0 }, { children: _jsx(NumberInput, { disabled: isViewOnly, max: 24, placeholder: "8" }) }))] })] }));
};
export var OvertimeCost = function () {
    var t = useTranslation(['wizard', 'translation']).t;
    var planType = usePlanType();
    return (_jsx(StyledBasePanel, { children: _jsx(BasePanel, __assign({ title: t('financial.overtimeCosts'), longDescription: t('financial.overtimeCostsDescription', { planType: planType }), statusType: QueueStatusType.Rate, needSaveBeforeCopy: true }, { children: _jsx(OvertimeCostContent, {}) })) }));
};
