var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Grid, Link, Typography, styled } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { usePlatformContext } from '../../app/selectors';
import { Autocomplete } from '../../common';
import { WizardContext } from '../context';
import { useQueuesCopyFrom } from '../queue/selectors';
import { useClearVolumeAPI, useCopyVolumeAPI } from '../workVolume/selectors';
var StyledContainer = styled(Grid)(function (_a) {
    var theme = _a.theme;
    return ({
        width: '50%',
        maxWidth: theme.typography.pxToRem(400),
    });
});
export var CopyDataFrom = function (props) {
    var t = useTranslation('wizard', { keyPrefix: 'copyData' }).t;
    var queues = useQueuesCopyFrom(props.statusType);
    var _a = useClearVolumeAPI(props.statusType), fetchClearData = _a[1];
    var _b = useCopyVolumeAPI(props.statusType), copyVolume = _b[1];
    var context = usePlatformContext();
    var _c = useContext(WizardContext), handleSave = _c.handleSave, isDirty = _c.methods.formState.isDirty;
    var handleCopy = function (source) {
        if (isDirty && props.needSaveBeforeCopy) {
            return handleSave('submit', function () { return copyVolume(source); })();
        }
        if (props.onCopy) {
            return props.onCopy(source);
        }
        return copyVolume(source);
    };
    var handleClear = function () {
        if (isDirty && props.needSaveBeforeCopy) {
            return handleSave('submit', function () { return fetchClearData(); })();
        }
        if (props.onClear) {
            return props.onClear();
        }
        return fetchClearData();
    };
    return (_jsxs(StyledContainer, __assign({ ml: "auto", container: true, alignItems: "center", columnSpacing: 2, mb: props.mb }, { children: [_jsx(Grid, __assign({ item: true, xs: 4.5, textAlign: "right" }, { children: _jsxs(Typography, { children: [" ", t('label')] }) })), _jsx(Grid, __assign({ item: true, xs: 7.5 }, { children: _jsx(Autocomplete, { disabled: props.disabled, blurOnSelect: true, options: queues, placeholder: t('placeholder', { context: context }), onChange: handleCopy, "data-testid": "copyDataSelect" }) })), !props.disableClear && (_jsx(Grid, __assign({ item: true, xs: 12, textAlign: "right" }, { children: _jsx(Link, __assign({ component: "button", type: "button", onClick: handleClear }, { children: t('clearData') })) })))] })));
};
