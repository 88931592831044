var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faCircleCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ErrorMessageBox } from '../common';
import { useForceUploadDataAPI, useUploadDataAPI } from './selectors';
var StyleDialogContent = styled(DialogContent)({
    textAlign: 'center',
});
var StyledButton = styled(LoadingButton)({
    minWidth: '10rem',
});
export var UploadSuccessStatusDialog = function () {
    var _a;
    var t = useTranslation(['myPlans', 'translation']).t;
    var uploadData = useUploadDataAPI()[0];
    var navigate = useNavigate();
    var handleClick = function () {
        var _a;
        navigate("/report/".concat((_a = uploadData.data) === null || _a === void 0 ? void 0 : _a.id));
    };
    return (_jsxs(Dialog, __assign({ open: !!((_a = uploadData.data) === null || _a === void 0 ? void 0 : _a.id) }, { children: [_jsx(DialogTitle, __assign({ variant: "h2", textAlign: "center" }, { children: t('upload.success') })), _jsxs(StyleDialogContent, { children: [_jsx(Typography, __assign({ color: "success.main", paddingBottom: 3 }, { children: _jsx(FontAwesomeIcon, { icon: faCircleCheck, size: "3x" }) })), _jsx(Typography, { children: t('upload.uploadFileSuccess') })] }), _jsx(DialogActions, __assign({ sx: { display: 'flex', justifyContent: 'center' } }, { children: _jsx(Button, __assign({ "data-testid": "done-btn", variant: "contained", type: "submit", onClick: handleClick }, { children: t('upload.viewPlan') })) }))] })));
};
export var UploadContinueStatusDialog = function (_a) {
    var _b;
    var onContinue = _a.onContinue;
    var t = useTranslation(['myPlans', 'translation']).t;
    var _c = useForceUploadDataAPI(), forceUploadData = _c[0], clearForceUploadData = _c[2];
    var _d = useUploadDataAPI(), uploadData = _d[0], clearUploadData = _d[2];
    var handleClose = function () {
        clearUploadData();
        clearForceUploadData();
    };
    return (_jsxs(Dialog, __assign({ open: !!((_b = uploadData.data) === null || _b === void 0 ? void 0 : _b.invalid), onClose: handleClose }, { children: [_jsx(DialogTitle, __assign({ variant: "h2", textAlign: "center" }, { children: t('upload.yourPlanHasBeenUploaded') })), _jsxs(StyleDialogContent, { children: [_jsx(ErrorMessageBox, { errors: [forceUploadData.error] }), _jsx(Typography, __assign({ color: "warning.main", paddingBottom: 3 }, { children: _jsx(FontAwesomeIcon, { icon: "triangle-exclamation", size: "3x" }) })), _jsx(Typography, { children: t('upload.thereWasMissingInFile') }), _jsx(Typography, { children: t('upload.youNeedToFillMissingData') })] }), _jsxs(DialogActions, __assign({ sx: { display: 'flex', justifyContent: 'center' } }, { children: [_jsx(Button, __assign({ "data-testid": "cancel-btn", variant: "outlined", onClick: handleClose }, { children: t('upload.cancelUpload') })), _jsx(StyledButton, __assign({ "data-testid": "done-btn", variant: "contained", type: "submit", loading: forceUploadData.requesting, onClick: onContinue }, { children: t('upload.continue') }))] }))] })));
};
