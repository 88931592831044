import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmDialog } from '../common';
import { useDeleteScenario, useReportAPI } from '../report/selectors';
import { useSummaryReportAPI } from './selectors';
export var DeleteScenarioDialog = function (_a) {
    var open = _a.open, scenario = _a.scenario, onClose = _a.onClose;
    var t = useTranslation(['report', 'translation']).t;
    var _b = useDeleteScenario(scenario === null || scenario === void 0 ? void 0 : scenario.id), deleteScenarioData = _b[0], deleteScenario = _b[1], clearDeleteScenario = _b[2];
    var _c = useReportAPI(), fetchData = _c[1];
    var _d = useSummaryReportAPI(), fetchSummaryData = _d[1];
    useEffect(function () {
        if (open && deleteScenarioData.data) {
            onClose();
            fetchData();
            fetchSummaryData();
        }
        return clearDeleteScenario;
    }, [deleteScenarioData.data, fetchSummaryData, onClose, fetchData, open, clearDeleteScenario]);
    var handleDeleteScenario = function () {
        deleteScenario();
    };
    return (_jsx(ConfirmDialog, { open: open, onClose: onClose, title: t('annual.deleteScenarioTitle', { scenarioName: scenario === null || scenario === void 0 ? void 0 : scenario.name }), message: t('translation:confirmation.yesDelete'), onConfirm: handleDeleteScenario, loading: deleteScenarioData.requesting, errors: [deleteScenarioData.error] }));
};
