var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useCallback, useEffect, useMemo } from 'react';
import { useMatch, useOutletContext } from 'react-router-dom';
import { REPORT_ROUTER_URL, REPORT_TYPE_PATTERN } from '../../router/constants';
import { useSummaryReportAPI } from '../annual/selectors';
import { useDownload } from '../hooks';
import { usePeriodReportAPI } from '../period/selectors';
import { ActionType } from './constants';
import { ViewType } from './resourcing';
import { useViewTypeParams } from './selectors';
export var useReportOutletContext = function () {
    return useOutletContext();
};
export var useResourcingContext = function () {
    return useOutletContext();
};
export var useSelectedTab = function () {
    var _a;
    var match = useMatch(REPORT_TYPE_PATTERN);
    return (_a = match === null || match === void 0 ? void 0 : match.params.tab) !== null && _a !== void 0 ? _a : REPORT_ROUTER_URL.Training;
};
export var useReportType = function () {
    var _a;
    var match = useMatch(REPORT_TYPE_PATTERN);
    return (_a = match === null || match === void 0 ? void 0 : match.params.reportType) !== null && _a !== void 0 ? _a : REPORT_ROUTER_URL.Annual;
};
export var useDisabledFinancial = function (viewType) {
    var annualData = useSummaryReportAPI()[0];
    var periodReportData = usePeriodReportAPI()[0];
    var reportType = useReportType();
    return useMemo(function () {
        var _a, _b, _c, _d;
        var annualTotalBudgets = (_b = (_a = annualData.data) === null || _a === void 0 ? void 0 : _a.reduce(function (acc, item) { return acc + item.totalBudgets; }, 0)) !== null && _b !== void 0 ? _b : 1;
        var periodTotalBudgets = (_d = (_c = periodReportData.data) === null || _c === void 0 ? void 0 : _c.reduce(function (acc, item) {
            var _a;
            var sum = (_a = item.totalBudgets) === null || _a === void 0 ? void 0 : _a.reduce(function (current, budget) { return Number(current) + Number(budget); }, 0);
            return acc + Number(sum);
        }, 0)) !== null && _d !== void 0 ? _d : 1;
        return (reportType === REPORT_ROUTER_URL.Daily ||
            (viewType === ViewType.Graphical && !annualTotalBudgets) ||
            (viewType === ViewType.Graphical && !periodTotalBudgets));
    }, [annualData.data, periodReportData.data, reportType, viewType]);
};
export var useReportAction = function (type, handler, requesting, disabled) {
    var _a;
    var _b = (_a = useReportOutletContext()) !== null && _a !== void 0 ? _a : {}, setOnExport = _b.setOnExport, setIsLoadingExport = _b.setIsLoadingExport, setOnPrint = _b.setOnPrint, setIsLoadingPrint = _b.setIsLoadingPrint;
    var setAction = type === ActionType.Export ? setOnExport : setOnPrint;
    var setLoading = type === ActionType.Export ? setIsLoadingExport : setIsLoadingPrint;
    var viewType = useViewTypeParams()[0];
    var isDisabled = disabled || (viewType === ViewType.Graphical && type === ActionType.Export);
    useEffect(function () {
        setAction === null || setAction === void 0 ? void 0 : setAction(isDisabled ? undefined : function () { return handler; });
        return setAction;
    }, [isDisabled, handler, setAction]);
    useEffect(function () {
        setLoading === null || setLoading === void 0 ? void 0 : setLoading(!!requesting);
    }, [requesting, setLoading]);
    return isDisabled;
};
export var useReportDownload = function (type, url, fileName, _a) {
    if (_a === void 0) { _a = {}; }
    var disabled = _a.disabled, params = _a.params, options = __rest(_a, ["disabled", "params"]);
    var _b = useDownload(url, fileName, options), data = _b[0], fetchData = _b[1], clearData = _b[2];
    var fetchDataWrapper = useCallback(function () {
        fetchData(undefined, params);
    }, [fetchData, params]);
    useReportAction(type, fetchDataWrapper, data.requesting, disabled);
    return [data, fetchDataWrapper, clearData];
};
