var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Stack } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Autocomplete, InputLabel } from '../../common';
import { useIsViewOnlyPlan } from '../../report/selectors';
import { usePlanType } from '../selectors';
import { useExceptionOptions } from './selectors';
var AddButton = function (props) {
    var t = useTranslation().t;
    return (_jsx(Button, __assign({}, props, { variant: "outlined" }, { children: t('action.add') })));
};
export var ExceptionForm = function (_a) {
    var onAdd = _a.onAdd, selectedMonths = _a.selectedMonths;
    var t = useTranslation('wizard', { keyPrefix: 'wapTime.addException' }).t;
    var planType = usePlanType();
    var isViewOnly = useIsViewOnlyPlan();
    var _b = useState(), month = _b[0], setMonth = _b[1];
    var options = useExceptionOptions(selectedMonths);
    var handleAddException = function () {
        if (month !== undefined) {
            onAdd(month);
        }
        setMonth(undefined);
    };
    return (_jsxs(Stack, __assign({ direction: "row", spacing: 2, alignItems: "center", mr: "auto" }, { children: [_jsx(InputLabel, { children: t('label') }), _jsx(Autocomplete, { disabled: isViewOnly, sx: { width: '15rem' }, options: options, value: month, onChange: setMonth, placeholder: t('placeholder', { planType: planType }) }), _jsx(AddButton, { onClick: handleAddException, disabled: month === undefined || isViewOnly })] })));
};
